import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Spin } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import IframeView from "../../organisms/Iframeview/IframeView";
import useApi from "../../../api/useApi";
import document from "../../../api/document";
import AppButton from "../../atoms/Buttons/AppButton";
import calculateDifferenceTimeStamps from "../../../helpers/calculateDifferenceTimeStamps";
import { useAuth } from "../../../contexts/AuthContext";

const DataProfiling = () => {
  const params = useParams();
  const getDescriptionDoc = useApi(document.getDescriptionDoc);
  const startingPandasProfiling = useApi(document.startingPandasProfiling);

  const [dataInfo, setDataInfo] = useState();
  // const [dataPandas, setDataPandas] = useState();
  const [loading, setLoading] = useState({
    data: false,
    pandas: false,
  });
  const [action, setAction] = useState("");
  const { setMessage } = useAuth();

  const gettingFileData = () => {
    setLoading((prev) => ({ ...prev, data: true }));
    getDescriptionDoc.request(params?.key);
  };

  useEffect(() => {
    gettingFileData();
  }, []);

  useEffect(() => {
    if (getDescriptionDoc.data) {
      const auxData = getDescriptionDoc.data?.data;
      setDataInfo(auxData);
      setLoading((prev) => ({ ...prev, data: false }));
      if (action === "pandas") {
        const auxUrlClean = auxData?.urlCleanData;
        if (auxUrlClean) {
          setLoading((prev) => ({ ...prev, pandas: true }));
          startingPandasProfiling.request({
            data_key: auxData?.urlCleanData,
            // data_key:
            //   "generic_data/9d8607ca-c785-4d8a-823e-2aefb5b87ca8/9d8607ca-c785-4d8a-823e-2aefb5b87ca8_clean.csv",
          });
        } else {
          setMessage({
            type: "info",
            title: "Error",
            description:
              "Primero debe ir a la pestaña 'Datos' y actualizar el Encabezado de la hoja",
          });
        }
      }
      getDescriptionDoc.setData();
    }
    if (startingPandasProfiling.data) {
      setLoading((prev) => ({ ...prev, pandas: false }));
      setAction("processing");
      startingPandasProfiling.setData();
    }
  }, [getDescriptionDoc.data, startingPandasProfiling.data]);

  useEffect(() => {
    if (startingPandasProfiling.error) {
      setAction("pandas");
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar calcular el Perfil de datos. Por favor vuelva a intentarlo mas tarde.",
      });
      setLoading((prev) => ({ ...prev, pandas: false }));
    }
  }, [startingPandasProfiling.error]);

  const calculatingDataProfiling = () => {
    gettingFileData();
    setAction("pandas");
  };

  const renderButtonCalculate = (titleButton) => (
    <AppButton
      className="!bg-lightBlue2 text-white  max-h-[120px] self-center"
      title={titleButton}
      onClick={calculatingDataProfiling}
    />
  );

  /*   Pending (al subir el archivo)
  Processing (al pinchar el botón de calcular o generar)
  Success (cuando el profile haya sido generado exitosamente)
  Failed (cuando el profile no se haya podido generar debido a un fallo) */
  const renderComponents = () => {
    if (!loading.data && !loading.pandas && dataInfo) {
      if (dataInfo.profileStatus === "success") {
        return (
          <div>
            <div className="flex w-full justify-end py-2 pr-2">
              {renderButtonCalculate("Re-calcular")}
            </div>
            <IframeView urlPandas={dataInfo.urlPandas}/>
          </div>
        );
      }
      if (dataInfo.profileStatus === "processing" || action === "processing") {
        const currentTime = Date.now();
        const startCalculationTime = dataInfo.pandasProfilingStartTime;
        const startCalculationTimestamp = new Date(
          startCalculationTime
        ).getTime();
        const minutesDiff = calculateDifferenceTimeStamps(
          startCalculationTimestamp,
          currentTime,
          "m"
        );
        return (
          <div className="flex flex-col">
            <Button
              className="self-end bg-lightBlue2 shadow-xl text-white_custom hover:text-lightBlue hover:shadow-none hover:bg-white_custom hover:border-lightBlue2"
              icon={<ReloadOutlined spin={loading.data} />}
              onClick={gettingFileData}
            >
              Actualizar
            </Button>
            <div className="p-5">
              <p>El Perfil general esta siendo calculado </p>
              <p>
                El tiempo transcurrido es:{" "}
                <strong>{minutesDiff} minutos</strong>
              </p>
              <p>
                El tiempo estimado para el procesamiento es:{" "}
                <strong>{dataInfo?.estimatedTime} minutos</strong>
              </p>
            </div>
          </div>
        );
      }
      return (
        <div className="flex justify-center items-center h-72">
          {renderButtonCalculate("Calcular")}
        </div>
      );
    }
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  };

  return <div>{renderComponents()}</div>;
};

export default DataProfiling;
