import React from "react";
import { Form } from "antd";
import AppMultiSelect from "../../atoms/MultiSelect/AppMultiSelect";
/* eslint-disable react/jsx-props-no-spreading */

const FormMultiSelect = ({
  className = "",
  name,
  label,
  onSelectOption,
  options,
  labelInValue = false,
  oldStyle = true,
  stylePopup,
  styleMultiselect,
  ...props
}) => {
  const combinedClassName = `font-Poppins text-black_custom ${oldStyle ? "formhome" : "multiStyle"} ${className}`;

  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <span className="font-Poppins font-light text-sizeBase text-color_text">
            {label}
          </span>
        ) : null
      }
      {...props}
      className={combinedClassName}
      rules={[
        {
          required: true,
          message: "Por favor seleccione una opción!",
        },
      ]}
    >
      <AppMultiSelect
        onSelectOption={onSelectOption || (() => {})}
        maxTagCount={2}
        maxTagTextLength={10}
        options={options}
        placeholder="Selecciona una opción"
        className={`rounded-full text-white_custom ${styleMultiselect}`}
        labelInValue={labelInValue}
        stylePopup={stylePopup}
      />
    </Form.Item>
  );
};

export default FormMultiSelect;
