import React, { useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";
// import Colors from "../../../utils/ColorsPage.json";

const ScatterPlot = ({ dataPlot, title, titleX, titleY, height = "100%" }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        window.requestAnimationFrame(() => {
          Plotly.Plots.resize(chartRef.current);
        });
      }
    };
    // Set initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const returnDataplot = () => {
    if (!Array.isArray(dataPlot)) {
      return [
        {
          type: "scatter",
          mode: "markers",
          x: [1, 2, 3, 4, 5],
          y: [1, 4, 6, 8, 10],
          name: "Series 2",
        },
      ];
    }
    return dataPlot;
  };

  return (
    <div ref={chartRef} style={{ width: "100%", height }}>
      <Plot
        layout={{
          title,
          autosize: true,
          xaxis: {
            title: titleX,
          },
          yaxis: {
            title: titleY,
          },
        }}
        data={returnDataplot()}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default ScatterPlot;
