/* eslint-disable no-param-reassign */
/* eslint-disable object-shorthand */
const transformToMap = (data) => {
  if(!data) return {type: "FeatureCollection", features: []};

  const eventCountsByLocation= {};

  const features = data.flatMap((item) => {
    if (item.collection_rows.length > 0) {
      const category=item.eventTypeId.eventCategoryId;
      const typeEvent  = item.eventTypeId.name;
      const id_event = (category === "salud")?item.eventTypeId.abb: item.eventTypeId.id_event;
      
      item.collection_rows.forEach((row) => {
        const coordKey = (category ==="salud")? `${row.latitude}-${row.longitude}`:`${row.LATITUD}-${row.LONGITUD}`;
        if (!eventCountsByLocation[coordKey]) {
          eventCountsByLocation[coordKey]={};
        } 
        if (!eventCountsByLocation[coordKey][typeEvent]) {
          eventCountsByLocation[coordKey][typeEvent]=0;
        }
        eventCountsByLocation[coordKey][typeEvent] += 1;
      });
      
      
      // eslint-disable-next-line no-use-before-define
      return createProperties(id_event,typeEvent, category, item.collection_rows);
    }
    return [];
  });

  features.forEach((feature) => {
    const coordKey = `${feature.geometry.coordinates[1]}-${feature.geometry.coordinates[0]}`;
    feature.properties.eventCounts = eventCountsByLocation[coordKey];
  });

  return {
    type: "FeatureCollection",
    features: features,
  };

};

const createProperties = (id_event,typeEvent, category, data) => {
  const elements = data.map((item) => {
    const properties = {
     
      municipio: (category === "salud") ? item.nom_mun : item.MUNICIPIO,
      evento: typeEvent,
      categoria: category,
      id_evento: id_event,
    };
    if (category === "salud") {
      properties.nombre_enfermedad = item.Nombre;
      properties.conteo_casos = item.conteo_casos;
    } else if (category === "violencia") {
      properties.presunto_responsable = item.PRESUNTO_RESPONSABLE;
      properties.total_victimas = item.TOTAL_DE_VICTIMAS_DEL_CASO;
      // eslint-disable-next-line dot-notation
      properties.modalidad = item.MODALIDAD;
    }

    return {
      type: "Feature",
      // eslint-disable-next-line object-shorthand
      properties: properties,
      geometry: {
        type: "Point",
        coordinates: (category ==="salud")? [item.longitude, item.latitude]:[item.LONGITUD, item.LATITUD],
      },
    };
  });
  return elements;

}


export default transformToMap;