import { Form } from "antd";
import FormInput from "../../molecules/FormInput/FormInput";
import SubmitButton from "../../atoms/Buttons/SubmitButton";

const TaxonomyEditTemplate = ({ onSubmit }) => {
  const [form] = Form.useForm();

  return (
    <Form onFinish={onSubmit} className="flex flex-col items-center">
      <FormInput
        name="name"
        label="Nombre de la taxonomía:"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Este campo es requerido",
          },
        ]}
        // onBlur={handleUrlBlur}
        className="w-[302px] mt-5"
      />
      <SubmitButton
        nameButton="Guardar"
        className=" mt-5"
        htmlType="submit"
        buttonColor="bg-lightGreen"
        // loading={loading}
        form={form}
      />
    </Form>
  );
};

export default TaxonomyEditTemplate;
