import { fetchAuthSession } from "@aws-amplify/auth";
import { useState } from "react";

export default (apiFunc) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasTriedRefresh, setHasTriedRefresh] = useState(false);

  const refreshToken = () => fetchAuthSession({ forceRefresh: true });

  const request = async (...args) => {
    setLoading(true);
    setError(null);
    setData(null);
    setHasTriedRefresh(false);
    try {
      const result = await apiFunc(...args);

      if ([200, 201, 202].includes(result.request.status)) {
        setData(result.data);
      } else {
        setError(result.response.data);

        if (result.request.status === 401 && !hasTriedRefresh) {
          setHasTriedRefresh(true); // Mark that we've tried refreshing the token

          try {
            const response = await refreshToken();

            if (response.userSub) {
              // Retry the original request after refreshing the token
              const result2 = await apiFunc(...args);
              setData(result2.data);
            } else {
              // If refreshing the token fails (e.g., no valid user session), reload or log out
              window.location.reload();
            }
          } catch (err) {
            setError({
              message: err.message || "Unexpected Error during token refresh!",
            });
          }
        } else if (result.request.status === 401 && hasTriedRefresh) {
          // Handle 401 even after refreshing the token (e.g., show an error or log out)
          setError({
            message:
              "Unauthorized, even after refreshing the token. Please log in again.",
          });
        }
      }
    } catch (err) {
      setError({ message: err.message || "Unexpected Error!" });
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    request,
    setData,
  };
};
