/* eslint-disable arrow-body-style */
// import { instance3 as client } from "./client_document";

const dataSource = [
  {
    key: "1",
    imgUrl: "https://www.onic.org.co/images/comunicados/internacionales/wola-brand-es.png",
    title: "Asalto a Líderes Sociales en Nariño",
    avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=1",
    author: "WOLA",
    date: "2021",
    description: "Violencia sistemática contra líderes sociales en Nariño con aumento de muertes desde el acuerdo de paz de 2016.",
    content: "Investigación sobre los asesinatos y los impactos en las comunidades."
  },
  {
    key: "2",
    imgUrl: "https://www.msf.org.co/wp-content/uploads/2023/05/intervencion_en_la_region_de_narino.jpg",
    title: "Respuesta de MSF a la Violencia en Nariño",
    avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=2",
    author: "Médicos Sin Fronteras",
    date: "2020",
    description: "Ayuda humanitaria y apoyo de salud mental en el violento despertar de Nariño.",
    content: "Estudio sobre disparidades en salud y estrategias de Médicos Sin Fronteras."
  },
  {
    key: "3",
    imgUrl: "https://www.colectivodeabogados.org/wp-content/uploads/2022/03/Minga-Awa_Colombia.jpg",
    title: "Desplazamiento del Pueblo Indígena AWA",
    avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=3",
    author: "Infobae",
    date: "2022-03-15",
    description: "Desplazamiento masivo de indígenas AWA debido al conflicto armado en Barbacoas, Nariño.",
    content: "Exploración de la historia del desplazamiento y efectos socio-políticos."
  },
  {
    key: "4",
    imgUrl: "https://www.unicef.org/colombia/sites/unicef.org.colombia/files/styles/two_column/public/Salud%20mental%201.jpeg.webp?itok=8R23zyn8",
    title: "Servicios de Salud Mental para Jóvenes en Nariño",
    avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=4",
    author: "International Journal of Mental Health Systems",
    date: "2021-11-23",
    description: "Evaluación de los servicios de salud mental para jóvenes afectados por el conflicto en Nariño.",
    content: "Discusión sobre atención comunitaria y estrategias de mejora."
  },
  {
    key: "5",
    imgUrl: "https://www.cruzrojacolombiana.org/wp-content/uploads/2019/09/LOGOS-CRUZ-ROJA-COLOMBIANA_RGB_150x150px.jpg",
    title: "Violencia Contra el Personal de Salud en Zonas de Conflicto",
    avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=5",
    author: "BMC Public Health",
    date: "2019",
    description: "Prevalencia de violencia contra trabajadores de salud en zonas de conflicto como Nariño.",
    content: "Análisis del impacto en la entrega de servicios de salud y el bienestar de los trabajadores."
  },
  {
    key: "6",
    imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpSRxd7AxoZa7MJxgtjZ0Uo1l0mot21llNlBvahD1Grg&s",
    title: "Iniciativas de Construcción de Paz en Nariño",
    avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=6",
    author: "Fondo de Objetivos de Desarrollo Sostenible",
    date: "No proporcionado",
    description: "Creación de planes de acción y comités para víctimas del conflicto armado en Nariño.",
    content: "Involucramiento de la comunidad en la recuperación y promoción de la paz."
  },
  {
    key: "7",
    imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe-sx-wtK-UQg-XT9Aq5KulmNIvl0Pgq4gtq3Lmzq0Jw&s",
    title: "Minas Terrestres - Una Amenaza Persistente en Nariño",
    avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=7",
    author: "Pressenza",
    date: "2023-08-22",
    description: "Aumento de víctimas de minas terrestres en Nariño y municipios circundantes.",
    content: "Informe sobre incidentes e impactos en la comunidad."
  },
  {
    key: "8",
    imgUrl: "https://estaticos.elcolombiano.com/documents/10157/0/620x250/0c0/0d0/none/11101/KNCR/balacera-conflicto-armado-medellin-620x250-25092011.jpg",
    title: "Determinantes de Conflictos en Nariño",
    avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=8",
    author: "Frontiers",
    date: "2020",
    description: "Investigación sobre factores ambientales y socioeconómicos que influyen en conflictos enNariño.",
    content: "Análisis a nivel municipal y patrones de conflicto."
  },
  {
    key: "9",
    imgUrl: "https://udsalamancaeir.files.wordpress.com/2021/05/unknown-1.jpeg?w=251",
    title: "Desafíos de la Atención Sanitaria Post-Violencia",
    avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=9",
    author: "Carlos López",
    date: "2024-04-15",
    description: "Estudio sobre la resiliencia del sistema de atención sanitaria frente a la violencia persistente en Nariño.",
    content: "Identificación de brechas y recomendaciones para el fortalecimiento del sistema."
  },
  {
    key: "10",
    imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh7huPQHLppj7wh2LWIDaBBMWkYZJaw46Py_aVVCdV6w&s",
    title: "Mujeres y Conflicto en Nariño",
    avatar: "https://api.dicebear.com/7.x/miniavs/svg?seed=10",
    author: "Ana Gómez",
    date: "2024-04-15",
    description: "Estudio centrado en género sobre los efectos del conflicto armado en las mujeres en Nariño.",
    content: "Análisis de la dinámica social y estrategias de protección."
  },
];






const getResearch = () => {
  return dataSource;
};

const getInvestigationData = (data) => {
  return dataSource.find((item) => item.key === data);
};



export default {
  getResearch,
  getInvestigationData,
};
