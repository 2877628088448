/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { Button, FloatButton, Switch } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import AppModal from "../../components/molecules/Modal/Modal";
import FormAutoSearch from "../../components/molecules/FormAutoSearch/FormAutoSearch";
import extractKeys from "../../helpers/extractKeys";
import useApi from "../../api/useApi";
import TableTemplate from "../../components/templates/TableTemplate";
import indicator from "../../api/indicator";
import JsonUploadForm from "../../components/organisms/DocumentUpload/JsonUploadForm";
import TaxonomyEditTemplate from "../../components/templates/TaxonomyTemplate/TaxonomyEditTemplate";

const Indicator = () => {
  const getListTaxonomies = useApi(indicator.getListTaxonomies);
  const [loading, setLoading] = useState({
    modal: false,
    create: false,
    search: false,
  });
  const [modalActive, setModalActive] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [modeSelected, setModeSelected] = useState("none");
  const [data, setData] = useState();
  const [dataTax, setDataTax] = useState();
  const [action, setAction] = useState("");
  const [metadata, setMetaData] = useState({
    total: 10,
    page: 1,
    page_size: 10,
  });

  //   const navigate = useNavigate();

  const gettingTaxonomies = () =>
    getListTaxonomies.setData({
      data: {
        items: [
          { _id: 14456, name: "Taxonomía 1", status: "active" },
          { _id: 2789, name: "Taxonomía 2", status: "active" },
          { _id: 32343, name: "Taxonomía 3", status: "active" },
          { _id: 42246, name: "Taxonomía 4", status: "inactive" },
          { _id: 565600, name: "Taxonomía 5", status: "inactive" },
          { _id: 68787, name: "Taxonomía 6", status: "inactive" },
          { _id: 71123, name: "Taxonomía 7", status: "inactive" },
        ],
      },
    });

  useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página
    gettingTaxonomies();
  }, [metadata.page]);

  useEffect(() => {
    if (getListTaxonomies.data) {
      setData(getListTaxonomies.data.data?.items);
    }
  }, [getListTaxonomies.data]);

  const handlePageChange = (page) => setMetaData((prev) => ({ ...prev, page }));

  const openModal = () => {
    setLoading((prev) => ({ ...prev, modal: true }));
    setModalActive(true);
  };

  const onEdit = (record) => {
    console.log("🚀 ~ onEdit ~ record:", record);
    openModal();
    setDataTax(record);
    setAction("edit");
  };

  const onCreate = () => {
    openModal();
    setAction("create");
  };

  const closeModal = () => {
    setModalActive(false);
    setLoading((prev) => ({ ...prev, modal: false }));
  };

  const getRowKeys = (idsToFind) => {
    const statuses = data
      .filter((item) => idsToFind.includes(item._id))
      .map((item) => item.status);
    const allActive = statuses.every((status) => status === "active");
    const allInactive = statuses.every((status) => status === "inactive");
    if (allActive) {
      setModeSelected("allActive");
    } else if (allInactive) {
      setModeSelected("allInactive");
    } else {
      setModeSelected("none");
    }
    setSelectedRowKeys(idsToFind);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (values) => getRowKeys(values),
  };

  const handlerSearch = async (val) => {
    if (val !== "") {
      setLoading((prev) => ({ ...prev, search: true }));
      setIsSearch(true);
    }
  };

  const onResetSearch = () => {
    setIsSearch(false);
    setLoading((prev) => ({ ...prev, search: false }));
  };

  const extractArray = () => extractKeys(data, selectedRowKeys, "id");

  const approveUser = () => {
    // TODO: action to approve multiple users
    const idUsers = extractArray();
    // const idUsers = extractArray();

    // TODO: DELETE
    setLoading((prev) => ({ ...prev, search: idUsers }));
  };

  const renderStatus = (value) => {
    if (value === "active") return "ACTIVA";
    if (value === "inactive") return "INACTIVA";
    return "";
  };

  const renderButtonMode = () => {
    if (modeSelected === "allActive") {
      return (
        <>
          <PauseOutlined className="mr-1" />
          <p>Desactivar</p>
        </>
      );
    }
    return (
      <>
        <PlayCircleOutlined className="mr-1" />
        <p>Activar</p>
      </>
    );
  };

  const renderActionMode = () => {
    if (modeSelected === "none") {
      return null;
    }
    return (
      <div
        className="bg-lightBlue2 text-white hover:shadow-xl shadow-none w-[110px] !px-2 h-6 rounded"
        onClick={approveUser}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            approveUser();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className="flex items-center justify-center h-full">
          {renderButtonMode()}
        </div>
      </div>
    );
  };

  const handleChangeStatus = (value, record) => {
    // TODO: request update status to id taxonomy
    const idTax = record._id;
    console.log(dataTax);
    console.log("🚀 ~ handleChangeStatus ~ idTax:", idTax);
    console.log("🚀 ~ handleChangeStatus ~ value:", value);
  };

  // Las columnas de la tabla
  const columns = [
    {
      title: (
        <span className="font-medium text-center text-xl">
          Nombre de la taxonomía
        </span>
      ),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record) => (
        <div>
          <span className="text-center text-black_custom ">{text}</span>
          <Button
            className="text-lightBlue2 border-none hover:text-lightBlue hover:shadow-none "
            onClick={() => onEdit(record)}
            icon={<EditOutlined />}
          />
        </div>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl">Estado</span>,
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => (
        <div>
          <span className="text-center text-black_custom">
            {renderStatus(text)}
          </span>
          <Switch
            defaultChecked={text === "active"}
            onChange={() => handleChangeStatus(text, record)}
            className="self-end"
          />
        </div>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl ">Acciones</span>,
      key: "acciones",
      align: "center",
      ellipsis: true,
      render: (_, record) => (
        <div className="flex space-x-2 justify-center  ">
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
            icon={<SyncOutlined />}
            onClick={() => onCreate(record)}
          />
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
            icon={<DownloadOutlined />}
            onClick={() => onEdit(record)}
          />
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
            icon={<DeleteOutlined />}
            onClick={() => onEdit(record)}
          />
        </div>
      ),
      width: 150,
      className: "border-b-0",
    },
  ];

  const onSubmitEdit = (values) => {
    console.log("🚀 ~ onSubmitEdit ~ values:", values);
  };

  const renderModalContent = () => {
    if (action === "create") return <JsonUploadForm />;
    if (action === "edit")
      return <TaxonomyEditTemplate onSubmit={onSubmitEdit} />;
    return null;
  };

  return (
    <div className="min-h-screen bg-white_custom flex flex-col items-center pt-10 ">
      <div className="w-10/12 mb-2 grid justify-items-end ">
        <Button
          shape="round"
          size="large"
          type="primary"
          className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
          loading={loading.modal}
          onClick={onCreate}
        >
          Crear nueva taxonomía
        </Button>
      </div>

      <div className=" w-11/12 bg-white_custom p-6 rounded-[45px] border border-colorBorder/25 shadow-2xl">
        <div>
          <FormAutoSearch
            loading={loading.search}
            submitSearch={handlerSearch}
            resetValue={!isSearch}
          />
          {isSearch && (
            <div style={{ textAlign: "right" }}>
              <Button
                shape="round"
                size="large"
                type="primary"
                className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
                onClick={onResetSearch}
              >
                Restablecer búsqueda
              </Button>
            </div>
          )}
        </div>
        <TableTemplate
          enableScroll
          data={data}
          handlePageChange={handlePageChange}
          total={metadata.total}
          columns={columns}
          page_size={metadata.page_size}
          currentPage={metadata.page}
          rowSelection={rowSelection}
          rowKey="_id"
          className=" "
        />
        {modalActive ? (
          <AppModal
            title={action === "create" ? "Nueva taxonomía" : "Editar taxonomía"}
            open={modalActive}
            onCancel={closeModal}
            footer={false}
            className=" text-lightBlue2 font-bold text-lg text-center w-1/2"
          >
            {renderModalContent()}
          </AppModal>
        ) : null}
        {selectedRowKeys.length > 0 ? (
          <FloatButton
            shape="square"
            className="min-w-fit px-5 py-2 bg-white cursor-default left-1/2 -translate-x-1/2"
            style={{ insetInlineEnd: 24 }}
            description={
              <div className="flex items-center md:space-x-2">
                <p>{selectedRowKeys.length} Elementos seleccionados</p>
                <div
                  className="bg-[#c85647] text-white hover:shadow-xl shadow-none w-[110px] h-6 rounded"
                  onClick={approveUser}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault();
                      approveUser();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <div className="flex items-center justify-center h-full">
                    <DeleteOutlined className="mr-1" />
                    Eliminar
                  </div>
                </div>
                {renderActionMode()}
              </div>
            }
          />
        ) : null}
      </div>
    </div>
  );
};
export default Indicator;
