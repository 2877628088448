/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { ConfigProvider, Divider } from "antd";
// import FormInput from "../../molecules/FormInput/FormInput";
import FormSelect from "../../molecules/FormSelect/FormSelect";
import FormTextArea from "../../molecules/FormTextArea/FormTextArea"; // import "../../../assets/css/styleSelectwhite.css";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";
import FormSelectSearch from "../../molecules/FormSelect/FormSelectSearch";
import FormInput from "../../molecules/FormInput/FormInput";
import FormDate from "../../molecules/FormDate/FormDate";
import FormTreeSelect from "../../molecules/FormTree/FormTreeSelect";

// eslint-disable-next-line arrow-body-style
const DocumentDescriptionForm = ({
  listColumns = [],
  listMunicipalities = [],
  listCodification,
  // validatorMunicipality,
  // validatorDate,
  action,
  dataDescription,
  validatorCode,
  WebSource = false,
  setInputValue,
  handleInputKeyDown,
  searchValue,
}) => {
  const [columnMunicipalitySelected, setColumnMunicipalitySelected] =
    useState(false);
  const [columnDateSelected, setColumnDateSelected] = useState(false);
  const [isWebSource, setIsWebSource] = useState(false);

  useEffect(() => {
    if (dataDescription) {
      if (dataDescription.municipalityColumn) {
        setColumnDateSelected(true);
      }
      if (dataDescription.dateColumn) {
        setColumnDateSelected(true);
      }
    }
  }, [dataDescription]);

  useEffect(() => {
    if (WebSource) {
      setIsWebSource(true);
    } else {
      setIsWebSource(false);
    }
  }, [WebSource]);

  const handleMunicipalityChange = (selectedValue) => {
    if (selectedValue === "0") {
      setColumnMunicipalitySelected(false);
    } else {
      setColumnMunicipalitySelected(true);
    }
  };


  const handleDateChange = (selectedValue) => {
    if (selectedValue === "0") {
      setColumnDateSelected(false);
    } else {
      setColumnDateSelected(true);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: selectWhiteStyle,
        },
      }}
    >
      <div className="flex flex-col md:flex-row mb-5 w-[calc(100vw-60px)] md:w-[600px]">
        <div className={action === "upload" ? "w-full" : "w-full md:w-1/2"}>
          <FormInput
            name="name"
            label="Nombre del documento:"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Este campo es requerido",
              },
            ]}
          />
          <FormTextArea
            name="description"
            className="text-color_text "
            classText="h-[105px]"
            label="Descripción general del documento :"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Este campo es requerido",
              },
            ]}
          />
          <FormInput
            name="urlWebSource"
            label="Url de la fuente de datos:"
            labelCol={{ span: 24 }}
            rules={[
              {
                type: "url",
                message: "La URL no es válida",
              },
              {
                max: 255,
                message: "La URL no puede tener más de 255 caracteres",
              },
            ]}
          />
          <FormSelect
            name="keyWords"
            label="Palabras clave:"
            className="font-semibold text-color_text text-textTable select-tags"
            selectClass="formBgWhite"
            searchValue={searchValue}
            onSearch={setInputValue}
            labelCol={{ span: 24 }}
            mode="tags"
            onInputKeyDown={handleInputKeyDown}
            tokenSeparators={[","]}
            tooltip="Para agregar palabras clave, escriba la palabra y luego presione la tecla Enter"
          />

          <FormDate
            name="cutOffDate"
            label="Fecha de corte:"
            className="text-color_text"
            labelCol={{ span: 24 }}
            tooltip="Seleccione una fecha de corte de los datos."
          />
        </div>
        {action === "edit" ? (
          <>
            <Divider
              type="vertical"
              className="h-auto border border-gray_custom hidden md:block"
            />
            <div className="w-full md:w-1/2">


              {!isWebSource ? (
                <FormSelectSearch
                  name="municipalityColumn"
                  label="Columna de Municipio:"
                  options={[
                    { value: "0", label: "Ninguno" },
                    ...listColumns,
                  ]}
                  labelCol={{ span: 24 }}
                  onChange={handleMunicipalityChange}
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                />
              ) : null}
              {columnMunicipalitySelected ? (
                <FormSelect
                  name="municipalityCode"
                  label="Codificación Municipio:"
                  className="font-semibold text-color_text text-textTable"
                  selectClass="formBgWhite"
                  options={listCodification}
                  labelCol={{ span: 24 }}
                  rules={validatorCode()}
                  tooltip="Seleccione el tipo de codificación del municipio:  DANE Completo: Incluye al inicio el código del departamento. DANE Sin Prefijo: No incluye el código del departamento."
                />
              ) : null}

              {!columnMunicipalitySelected || isWebSource ? (
                // <FormSelectSearch
                <FormTreeSelect
                  name="municipality"
                  label="Seleccione Municipio:"
                  className=" text-color_text treeMunicipality"
                  labelCol={{ span: 24 }}
                  tooltip="Si el archivo no contiene una columna 'Municipio', seleccione un municipio en este campo."
                  treeData={listMunicipalities}
                  maxTagCount={4}
                />
              ) : null}
              {!isWebSource ? (
                <FormSelectSearch
                  name="dateColumn"
                  label="Columna de Fecha de muestra:"
                  className="font-semibold text-color_text "
                  options={[
                    { value: "0", label: "Ninguno" },
                    ...listColumns,
                  ]}
                  labelCol={{ span: 24 }}
                  onChange={handleDateChange}
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                />
              ) : null}
              {!columnDateSelected || isWebSource ? (
                <FormDate
                  name="dateRange"
                  label="Seleccione la fecha de muestra:"
                  className=" text-color_text"
                  labelCol={{ span: 24 }}
                  tooltip="Si el archivo no contiene una columna 'Fecha de muestra', seleccione una fecha en este campo."
                  // requiredActive={false}
                />
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </ConfigProvider>
  );
};

export default DocumentDescriptionForm;
