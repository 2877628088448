import api from ".";
import newApi from "./newApi";
import noAuth from "./client_no_auth_new";
import client_img from "./client_img";

const createUser = (data) => {
  const url = "users/signup";
  return noAuth.post(url, data);
};

const getInfoUser = (id) => {
  const url = `/users/user/${id}`;
  return newApi.get(url);
};

const getAllUsers = (filters, page, page_size, sort, or_operator) => {
  let url = `/users/list?page_size=${page_size}&page=${page}`;
  if(filters) url = url.concat("&",`filters=${filters}`);
  if(sort) url = url.concat("&",`sort=${sort}`);
  if(or_operator) url = url.concat("&",`or_operator=${or_operator}`);
  const auxUrl = encodeURI(url)
  return newApi.get(auxUrl);
};

const updateUser = (data) => {
  const url = "/users/update";
  return api.put(url, data);
};

const uploadImage = (file, fileName, fileExtension, size) => {
  const url = `/users/upload-image?fileName=${fileName}&docType=user&fileExtension=${fileExtension}&size=${size}`;
  return client_img.post(url, file);
};

const uploadPdf = (id, file, fileName, fileExtension, size, source) => {
  const url = `/users/upload-pdf?fileName=${fileName}&docType=user&fileExtension=${fileExtension}&size=${size}&source=${source}&id=${id}`;
  return client_img.post(url, file);
};

export default {
  createUser,
  getInfoUser,
  getAllUsers,
  updateUser,
  uploadImage,
  uploadPdf,
};
