/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from "react";
import { Button, Spin, Tooltip } from "antd";
import {
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AppTitle from "../../components/atoms/Title/AppTitle";
import AppModal from "../../components/molecules/Modal/Modal";
import document from "../../api/document";
import useApi from "../../api/useApi";
import transformToDateFormat from "../../helpers/transformToDateFormat";
import convertDate from "../../helpers/dateUtil";
import ModalConfirm from "../../components/molecules/Modal/ModalConfirm";
import TableTemplate from "../../components/templates/TableTemplate";
import downloadFile from "../../helpers/downloadFile";
import DocumentRequest from "../../api/DocumentRequest";
import ROUTES from "../../constant/router";
import { useAuth } from "../../contexts/AuthContext";
import InvestigationFormTemplate from "../../components/templates/DocumentTemplate/InvestigationFormTemplate";
// import transformToTreeSelect from "../../helpers/transformToTreeSelect";
import breakCellText from "../../helpers/breakCellText";
import ValidatePermissions from "../../helpers/ValidatePermissions";
import transformToTreeSelectRegions from "../../helpers/transformToTreeSelectRegions";
import sanitizeFileName from "../../helpers/sanitizeFileName";

const AdminInvestigation = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const page_size = 10;
  const [loading, setLoading] = useState({
    general: false,
    list: false,
    description: false,
    descriptionModal: false,
    download: false,
    remove: false,
  });
  const [dataDescription, setDataDescription] = useState();
  const [modalActive, setModalActive] = useState(false);
  const [modalActiveConfirm, setModalActiveConfirm] = useState(false);
  const getAllDocuments = useApi(document.getAllResearchMeta);
  const getDescriptionDoc = useApi(document.getResearchMetaById);
  const createDescriptionDoc = useApi(document.createResearchMeta);
  const downloadDocument = useApi(document.downloadDocument);
  const updateResearchMeta = useApi(document.updateResearchMeta);
  const uploadImageResearchMeta = useApi(document.uploadImageResearchMeta);
  const previousStateResearchMeta = useApi(document.previousStateResearchMeta);
  // const {
  //   request: getMunicipalities,
  //   data: municipalities,
  //   setData: setDataMunicipalities,
  // } = useApi(DocumentRequest.getMunicipalities);
  const getMunicipalityForRegion = useApi(DocumentRequest.getMunicipalitiesSubRegions);
  const [data, setData] = useState();
  const { setMessage, currentUser } = useAuth();
  const [total, setTotal] = useState(0);
  const [action, setAction] = useState("");
  const [listMunicipalities, setListMunicipalities] = useState([]);
  const [idDocument, setIdDocument] = useState();
  const [infoFile, setInfoFile] = useState({ name: null, extension: null });
  const [file, setFile] = useState(null);

  const navigate = useNavigate();

  const reloadListDocs = () => {
    getAllDocuments.request(page_size, currentPage);
  };

  useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página
    reloadListDocs();
    // getMunicipalities(52);
    getMunicipalityForRegion.request();
    setLoading((prev) => ({ ...prev, list: true }));
  }, [currentPage]);

  const handlePageChange = (page) => {
    // hacer la petición a la API para obtener los datos de la página
    setCurrentPage(page);
  };

  const onDownloadDocument = (keyDoc) => {
    setLoading((prev) => ({ ...prev, download: true }));
    downloadDocument.request(keyDoc);
  };

  const onView = (key) => {
    // redirigir a la vista de la descripción de la investigación
    const path = ROUTES.GENERAL.INVESTIGATION_INFO.replace(":key", key);
    navigate(path);
  };

  const onEdit = (key) => {
    getDescriptionDoc.request(key);
    setIdDocument(key);
    setAction("edit");
    setLoading((prev) => ({
      ...prev,
      description: { ...prev.description, [key]: true },
    }));
  };

  const onClickRow = (obj) => {
    if (obj?.state !== "Suspendida") {
      const { key } = obj;
      getDescriptionDoc.request(key);
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [key]: true },
      }));
      setLoading((prev) => ({ ...prev, descriptionModal: true }));
      setIdDocument(key);
      setAction("view");
    } else {
      setMessage({
        type: "info",
        title: "",
        description:
          "No puedes realizar ninguna acción mientras el estado de la investigación este en 'Suspendida'",
      });
    }
  };

  const closeModal = () => {
    setModalActive(false);
    setLoading((prev) => ({ ...prev, general: false }));
  };

  const confirmCloseModal = () => {
    setModalActive(false);
    setModalActiveConfirm(false);
    setLoading((prev) => ({ ...prev, general: false }));
  };

  const openModal = () => {
    setAction("create");
    setDataDescription();
    setLoading((prev) => ({ ...prev, general: true }));
    setModalActive(true);
  };

  const handlerSubmitDescription = (val) => {
    
    const auxDate = val?.date;
    const { upload, avatar,location,locationSubRegion, ...rest } = val;
    const startDate = transformToDateFormat(auxDate);
    let auxLocation = [];
    if(val.localizationType === "subRegion"){
      auxLocation = locationSubRegion;
    }else {
      auxLocation = location;
    }
    let auxVal = { ...rest,location:auxLocation, date: startDate, state: "En Creación" };
    delete auxVal.imageUrl;
    if (action === "create") {
      const auxVersion = {
        author: val.author,
        date: startDate,
        versionName: "v1.0",
        state: "En Creación",
      };
      auxVal = { ...auxVal, version: auxVersion };
      createDescriptionDoc.request(auxVal);
      setFile(upload);
    } else {
      // action === "edit"
      updateResearchMeta.request(idDocument, auxVal);
      setFile(upload);
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: true },
      }));
    }
    setLoading((prev) => ({ ...prev, descriptionModal: true }));
  };

  useEffect(() => {
    if (getDescriptionDoc.data) {
      const auxData = getDescriptionDoc.data;
      if (action === "view") {
        onView(idDocument);
      } else if (action === "edit") {
        setModalActive(true);
        setDataDescription(auxData);
      }
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: false },
      }));

      getDescriptionDoc.setData();
    }
    if (getAllDocuments.data) {
      const documents = getAllDocuments.data?.data;
      const pagination = getAllDocuments.data?.meta;
      const auxData = documents.map((item) => ({
        ...item,
        key: item.id,
        createdAt: convertDate(item.createdAt),
      }));
      setTotal(pagination.total);
      setData(auxData);
      setLoading((prev) => ({ ...prev, list: false }));

      getAllDocuments.setData();
    }

    if (downloadDocument.data) {
      downloadFile(downloadDocument.data, infoFile.name, ".csv");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El archivo fue descargado exitosamente.",
      });
      setLoading((prev) => ({ ...prev, download: false }));
      downloadDocument.setData();
    }
    if (updateResearchMeta.data) {
      if (file) {
        const { name, size } = file;
        const safeName = sanitizeFileName(name);
        const extension = name.split(".").pop();
        const formData = new FormData();
        formData.append("image", file.originFileObj, safeName);
        uploadImageResearchMeta.request(
          formData,
          safeName,
          extension,
          size,
          idDocument,
          "research"
        );
      }
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La investigación fue actualizada exitosamente.",
      });
      setModalActive(false);
      reloadListDocs();
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: false },
      }));
      updateResearchMeta.setData();
    }
  }, [
    getAllDocuments.data,
    getDescriptionDoc.data,
    downloadDocument.data,
    updateResearchMeta.data,
  ]);

  useEffect(() => {
    // if (municipalities) {
    //   console.log("municipios",municipalities);
    //   const aux = transformToTreeSelect(
    //     municipalities,
    //     "municipio",
    //     "municipio"
    //   );
    //   console.log(aux);
    //   setListMunicipalities(aux);
    //   setDataMunicipalities();
    // }
    if(getMunicipalityForRegion.data){
      const aux = transformToTreeSelectRegions(
        getMunicipalityForRegion.data,
        "subRegion",
        "subRegion"
      );
      setListMunicipalities(aux);
      getMunicipalityForRegion.setData();
    }
   
    if (previousStateResearchMeta.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La investigación fue actualizada exitosamente.",
      });
      reloadListDocs();
      previousStateResearchMeta.setData();
    }
    if (createDescriptionDoc.data) {
      const auxData = createDescriptionDoc.data;
      if (file) {
        const { name, size } = file;
        // setFile(upload.originFileObj);
        const extension = name.split(".").pop();
        const safeName = sanitizeFileName(name);
        const formData = new FormData();
        formData.append("image", file.originFileObj, safeName);
        uploadImageResearchMeta.request(
          formData,
          safeName,
          extension,
          size,
          auxData.id,
          "research"
        );
      }
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La investigación se ha creado correctamente",
      });
      setModalActive(false);
      reloadListDocs();
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({ ...prev, general: false }));
      createDescriptionDoc.setData();
    }
  }, [
    // municipalities,
    getMunicipalityForRegion.data,
    createDescriptionDoc.data,
    previousStateResearchMeta.data,
  ]);

  useEffect(() => {
    if (getDescriptionDoc.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos. Vuelva a intentarlo.",
        code: getDescriptionDoc.error,
      });
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: false },
      }));
    }
    if (getAllDocuments.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos. Vuelva a intentarlo.",
        code: getAllDocuments.error,
      });
      setLoading((prev) => ({ ...prev, list: false }));
    }
    if (createDescriptionDoc.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Por favor inténtelo nuevamente.",
        code: createDescriptionDoc.error,
      });
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: false },
      }));
    }
    if (downloadDocument.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar descargar el archivo. Inténtelo de nuevo mas tarde.",
        code: downloadDocument.error,
      });
      setLoading((prev) => ({ ...prev, download: false }));
    }
    if (updateResearchMeta.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar actualizar la investigación. Inténtelo de nuevo mas tarde.",
        code: updateResearchMeta.error,
      });
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({
        ...prev,
        description: { ...prev.description, [idDocument]: false },
      }));
      updateResearchMeta.setData();
    }
    if (uploadImageResearchMeta.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar subir la imagen. Inténtelo de nuevo.",
        code: uploadImageResearchMeta.error,
      });
      uploadImageResearchMeta.setData();
    }
    if (previousStateResearchMeta.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar subir actualizar el estado. Inténtelo de nuevo.",
        code: previousStateResearchMeta.error,
      });
      previousStateResearchMeta.setData();
    }
  }, [
    getDescriptionDoc.error,
    createDescriptionDoc.error,
    getAllDocuments.error,
    downloadDocument.error,
    updateResearchMeta.error,
    uploadImageResearchMeta.error,
    previousStateResearchMeta.error,
  ]);

  const renderIcon = (record) => {
    if (!loading.remove[record.key]) {
      if (currentUser.role === "Administrador") {
        if (record.state === "Suspendida") return <PlayCircleOutlined />;
        return <PauseCircleOutlined />;
      }
      return <DeleteOutlined />;
    }
    return <Spin />;
  };

  const onChangeState = (record) => {
    if (currentUser.role === "Administrador") {
      if (record.state === "Suspendida") {
        previousStateResearchMeta.request(record.key);
      } else {
        updateResearchMeta.request(record.key, { state: "Suspendida" });
      }
    } else if (currentUser.role === "Investigador") {
      updateResearchMeta.request(record.key, {
        state: "Eliminada",
        isDeleted: true,
      });
    }
  };
  // Las columnas de la tabla
  const columns = [
    {
      title: <span className="font-medium text-center text-xl ">Código</span>,
      dataIndex: "code",
      key: "code",
      align: "center",
      render: (text) => (
        <span className="  text-center text-sizeBase text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      width: 150,
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: <span className="font-medium text-center text-xl ">Título</span>,
      dataIndex: "title",
      key: "title",
      align: "center",
      render: (text) => (
        <span className="  text-center text-sizeBase text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      width: 250,
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: <span className="font-medium text-center text-xl">Autor</span>,
      dataIndex: "author",
      key: "author",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom">{text}</span>
      ),
      width: 150,
      className: "border-b-0",
      sorter: (a, b) => a.author.localeCompare(b.author),
    },
    {
      title: <span className="font-medium text-center text-xl">Resumen</span>,
      dataIndex: "abstract",
      key: "abstract",
      align: "center",
      width: 300,
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          <span className="rounded-md text-center text-black_custom">
            {breakCellText(text, 100)}
          </span>
        </Tooltip>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl">Estado</span>,
      dataIndex: "state",
      key: "state",
      align: "center",
      render: (text) => (
        <span className=" rounded-md text-center text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      filters: [
        {
          text: "En Creación",
          value: "En Creación",
        },
        {
          text: "En Revisión",
          value: "En Revisión",
        },
        {
          text: "Publicada",
          value: "Publicada",
        },
        {
          text: "Suspendida",
          value: "Suspendida",
        },
        {
          text: "Eliminada",
          value: "Eliminada",
        },
        {
          text: "En Edición",
          value: "En Edición",
        },
      ],
      onFilter: (value, record) => record.state.indexOf(value) === 0,
    },
    {
      title: (
        <span className="font-medium text-center text-xl">
          Fecha de creación
        </span>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text) => (
        <span className=" rounded-md text-center text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: <span className="font-medium text-center text-xl ">Acciones</span>,
      key: "acciones",
      align: "center",
      ellipsis: true,
      render: (text, record) => (
        <div className="flex space-x-2 justify-center  ">
          <ValidatePermissions
            container={false}
            func="actionsTable"
            permission={["edit", "pause", "download"]}
            modulePermission="research"
          >
            <Button
              className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
              icon={
                !loading.description[record.key] ? <EditOutlined /> : <Spin />
              }
              onClick={(e) => {
                onEdit(record.key);
                e.stopPropagation();
              }}
              disabled={record.state === "Suspendida"}
            />
            <Button
              className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
              icon={renderIcon(record)}
              onClick={(e) => {
                onChangeState(record);
                e.stopPropagation();
              }}
            />
            <Button
              className="text-lightBlue2 shadow-xl  w-12 hover:text-lightBlue hover:shadow-none "
              icon={
                !loading.download[record.key] ? <DownloadOutlined /> : <Spin />
              }
              onClick={(e) => {
                onDownloadDocument(record.key);
                setInfoFile((prev) => ({
                  ...prev,
                  name: record.name,
                  extension: record.fileExtension,
                }));
                e.stopPropagation();
              }}
              disabled={record.state === "Suspendida"}
            />
          </ValidatePermissions>
        </div>
      ),
      width: 150,
      className: "border-b-0",
    },
  ];

  const renderModal = () => {
    if (modalActive) {
      return (
        <AppModal
          open={modalActive}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 text-lg text-center w-full lg:w-1/2"
        >
          <InvestigationFormTemplate
            documentKey={1}
            action={action}
            dataDescription={dataDescription}
            handlerSubmit={handlerSubmitDescription}
            loading={loading.descriptionModal}
            listMunicipalities={listMunicipalities}
            hasTitle
          />
        </AppModal>
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-white_custom flex flex-col items-center pt-10 ">
      <div className="w-10/12 mb-2 grid justify-items-end ">
        <ValidatePermissions
          func="oneButton"
          container={false}
          permission="create"
          modulePermission="research"
        >
          <Button
            shape="round"
            size="large"
            type="primary"
            className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
            loading={loading.general}
            onClick={openModal}
          >
            Crear nueva investigación académica
          </Button>
        </ValidatePermissions>
      </div>

      <div className=" w-11/12 bg-white_custom p-6 rounded-[45px] border border-colorBorder/25 shadow-2xl">
        <div className="flex justify-center items-center mb-4">
          <AppTitle
            title="Investigaciones Académicas"
            level={4}
            className="text-lightBlue2 font-bold  text-center !important"
          />
        </div>
        {!loading.list ? (
          <TableTemplate
            enableScroll
            data={data}
            handlePageChange={handlePageChange}
            total={total}
            columns={columns}
            page_size={page_size}
            currentPage={currentPage}
            className=" "
            onSelectRow={onClickRow}
            isClickRowActive
          />
        ) : (
          <div className="flex justify-center items-center h-56">
            <Spin />
          </div>
        )}
        {renderModal()}
      </div>
      <ModalConfirm
        centered
        okText="Aceptar"
        handleOK={confirmCloseModal}
        closeModal={() => setModalActiveConfirm(false)}
        modalActive={modalActiveConfirm}
        text="¿Estas seguro que deseas eliminar la investigación?"
      />
    </div>
  );
};
export default AdminInvestigation;
