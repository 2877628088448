// App.js
import React, { useState, useEffect } from "react";
import { Button, FloatButton } from "antd";
import { EditOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
import AppTitle from "../../atoms/Title/AppTitle";
import AppModal from "../../molecules/Modal/Modal";
// import ROUTES from "../../../constant/router";
import TableTemplate from "../TableTemplate";
import FormAutoSearch from "../../molecules/FormAutoSearch/FormAutoSearch";
import CreateForm from "../../organisms/UserForm/CreateForm";
import extractKeys from "../../../helpers/extractKeys";
import useApi from "../../../api/useApi";
import user from "../../../api/user";

const UsersList = () => {
  const roles = [
    {
      id: 1,
      value: "Funcionario de Gobierno",
      label: "Funcionario Público ",
    },
    {
      id: 2,
      value: "Usuario General",
      label: "Usuario General",
    },
  ];
  /*  const dataSource = [
    {
      id: "1",
      name: "John",
      lastName: "Doe",
      email: "john.doe@example.com",
      phone: "123-456-7890",
      gender: "male",
      role: "Investigador",
      createdAt: "Monday",
      status: "pending",
    },
    {
      id: "2",
      name: "Jane",
      lastName: "Smith",
      email: "jane.smith@example.com",
      phone: "987-654-3210",
      gender: "female",
      role: "Funcionario Publico",
      createdAt: "Tuesday",
      status: "approved",
    },
    {
      id: "3",
      name: "Alex",
      lastName: "Johnson",
      email: "alex.johnson@example.com",
      phone: "555-555-5555",
      gender: "non-binary",
      role: "Investigador",
      createdAt: "Wednesday",
      status: "denied",
    },
    {
      id: "4",
      name: "Maria",
      lastName: "Garcia",
      email: "maria.garcia@example.com",
      phone: "777-777-7777",
      gender: "female",
      role: "Investigador",
      createdAt: "Thursday",
      status: "denied",
    },
    {
      id: "5",
      name: "Chris",
      lastName: "Lee",
      email: "chris.lee@example.com",
      phone: "333-333-3333",
      gender: "male",
      role: "Investigador",
      createdAt: "Friday",
      status: "pending",
    },
  ]; */
  const getAllUsers = useApi(user.getAllUsers);
  const [loading, setLoading] = useState({
    modal: false,
    create: false,
    search: false,
  });
  const [modalActive, setModalActive] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  const [data, setData] = useState();
  const [dataUser, setDataUser] = useState();
  const [action, setAction] = useState("");
  const [metadata, setMetaData] = useState({
    total: 10,
    page: 1,
    page_size: 10,
  });

  //   const navigate = useNavigate();

  const gettingUsersData = () =>
    getAllUsers.request(null, metadata.page, metadata.page_size);

  useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página
    gettingUsersData();
  }, [metadata.page]);

  useEffect(() => {
    if (getAllUsers.data) {
      // eslint-disable-next-line no-console
      console.log("🚀 ~ useEffect ~ getAllUsers.data.data?.items:", getAllUsers.data.data?.items)
      // const auxData = dataSource.map((item, i) => ({ ...item, key: i }));
      setData(getAllUsers.data.data?.items);
    }
  }, [getAllUsers.data]);

  const handlePageChange = (page) => setMetaData((prev) => ({ ...prev, page }));

  const openModal = () => {
    setLoading((prev) => ({ ...prev, modal: true }));
    setModalActive(true);
  };

  const onEdit = (record) => {
    openModal();
    setDataUser(record);
    setAction("edit");
  };

  const onCreate = () => {
    openModal();
    setAction("create");
  };

  const closeModal = () => {
    setModalActive(false);
    setLoading((prev) => ({ ...prev, modal: false }));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handlerSearch = async (val) => {
    if (val !== "") {
      setLoading((prev) => ({ ...prev, search: true }));
      setIsSearch(true);
    }
  };

  const onResetSearch = () => {
    setIsSearch(false);
    setLoading((prev) => ({ ...prev, search: false }));
  };

  const extractArray = () => extractKeys(data, selectedRowKeys, "id");

  const approveUser = () => {
    // TODO: action to approve multiple users
    const idUsers = extractArray();
    // const idUsers = extractArray();

    // TODO: DELETE
    setLoading((prev) => ({ ...prev, search: idUsers }));
  };
  const denyUser = () => {
    // TODO: action to deny multiple users
    const idUsers = extractArray();

    // TODO: DELETE
    setLoading((prev) => ({ ...prev, search: idUsers }));
  };

  // Las columnas de la tabla
  const columns = [
    {
      title: <span className="font-medium text-center text-xl">Rol</span>,
      dataIndex: "role",
      key: "role",
      align: "center",
      render: (text) => (
        <span className="text-center text-black_custom ">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: (
        <span className="font-medium text-center text-xl ">
          Nombre completo
        </span>
      ),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, record) => (
        <span className="  text-center text-sizeBase text-black_custom ">
          {record?.name} {record?.lastName}
        </span>
      ),
      className: "border-b-0",
      width: 250,
    },
    {
      title: <span className="font-medium text-center text-xl">Correo</span>,
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (text) => (
        <span className="text-center text-black_custom ">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl">Teléfono</span>,
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (text) => (
        <span className="text-center text-black_custom">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: (
        <span className="font-medium text-center text-xl">
          Fecha de creación
        </span>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text) => (
        <span className="text-center text-black_custom">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl ">Acciones</span>,
      key: "acciones",
      align: "center",
      ellipsis: true,
      render: (_, record) => (
        <div className="flex space-x-2 justify-center  ">
          {record?.status === "pending" || record?.status === "denied" ? (
            <Button
              className="bg-aquamarineBlue text-white shadow-xl hover:shadow-none "
              onClick={() => onEdit(record.key)}
            >
              Dar de alta
            </Button>
          ) : null}
          {record?.status === "pending" || record?.status === "approved" ? (
            <Button
              className="bg-[#939393] text-white shadow-xl hover:shadow-none "
              onClick={() => onEdit(record.key)}
            >
              Dar de baja
            </Button>
          ) : null}
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
            icon={<EditOutlined />}
            onClick={() => onEdit(record)}
          />
        </div>
      ),
      width: 150,
      className: "border-b-0",
    },
  ];

  return (
    <div className="min-h-screen bg-white_custom flex flex-col items-center pt-10 ">
      <div className="w-10/12 mb-2 grid justify-items-end ">
        <Button
          shape="round"
          size="large"
          type="primary"
          className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
          loading={loading.modal}
          onClick={onCreate}
        >
          Crear usuario
        </Button>
      </div>

      <div className=" w-11/12 bg-white_custom p-6 rounded-[45px] border border-colorBorder/25 shadow-2xl">
        <div>
          <FormAutoSearch
            loading={loading.search}
            submitSearch={handlerSearch}
            resetValue={!isSearch}
          />
          {isSearch && (
            <div style={{ textAlign: "right" }}>
              <Button
                shape="round"
                size="large"
                type="primary"
                className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
                onClick={onResetSearch}
              >
                Restablecer búsqueda
              </Button>
            </div>
          )}
        </div>
        <div className="flex justify-center items-center mb-4">
          <AppTitle
            title="Usuarios registrados"
            level={3}
            className="text-lightBlue2 font-bold  text-center w-1/2"
          />
        </div>

        <TableTemplate
          enableScroll
          data={data}
          handlePageChange={handlePageChange}
          total={metadata.total}
          columns={columns}
          page_size={metadata.page_size}
          currentPage={metadata.page}
          rowSelection={rowSelection}
          rowKey="_id"
          className=" "
        />
        <AppModal
          title="Nuevo usuario"
          open={modalActive}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 font-bold text-lg text-center w-1/2"
        >
          <CreateForm
            roles={roles}
            loading={loading.create}
            dataUser={dataUser}
            action={action}
          />
        </AppModal>
        {selectedRowKeys.length > 0 ? (
          <FloatButton
            shape="square"
            className="min-w-fit px-5 py-2 bg-white cursor-default left-1/2 -translate-x-1/2"
            style={{ insetInlineEnd: 24 }}
            description={
              <div className="md:space-x-2">
                <Button
                  className="bg-aquamarineBlue text-white hover:shadow-xl shadow-none w-[110px] "
                  onClick={approveUser}
                >
                  Dar de alta
                </Button>
                <Button
                  className="bg-[#939393] text-white hover:shadow-xl shadow-none w-[110px] "
                  onClick={denyUser}
                >
                  Dar de baja
                </Button>
              </div>
            }
          />
        ) : null}
      </div>
    </div>
  );
};
export default UsersList;
