/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Form, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import HomeForm from "../../organisms/HomeForm/HomeForm";
import MapCard from "../../organisms/MapCard/MapCard";
import useApi from "../../../api/useApi";
import MapRequest from "../../../api/MapRequest";
import DocumentRequest from "../../../api/DocumentRequest";
import transformToSelectArray from "../../../helpers/transformToSelectArray";
import transformToCheckArray from "../../../helpers/transformToCheckArray";
import transformToMap from "../../../helpers/transformToMap";
import { useAuth } from "../../../contexts/AuthContext";
import HomeLastFiles from "../../organisms/HomeForm/HomeLastFiles";
import HomePreviewIndicatorChart from "../../organisms/HomeForm/HomePreviewIndicatorChart";
import document from "../../../api/document";

const HomeTemplate = ({
  handleItemZone,
  handleItemEvent,
  zonesSelected,
  eventsSelected,
}) => {
  const deptoCode = 52;
  const [mapData, setMapData] = useState([]);
  const requestDataMap = useApi(MapRequest.getDataMap);
  const [municipalitiesList, setMunicipalitiesList] = useState([]);
  const [listEvents, setListEvents] = useState([]);
  const [listHealthEvents, setListHealthEvents] = useState([]);
  const [form] = Form.useForm();
  const { setMessage, currentUser } = useAuth();
  const navigate = useNavigate();

  const {
    request: getMunicipalities,
    data: municipalities,
    setData: setDataMunicipalities,
  } = useApi(DocumentRequest.getMunicipalities);
  const {
    request: getAllEvents,
    data: events,
    setData: setEvents,
  } = useApi(MapRequest.getAllEvents);
  const getAllDocuments = useApi(document.getAllDocuments);

  const getMyLastDocuments = () => getAllDocuments.request(3, 1, "my-files");

  useEffect(() => {
    getMunicipalities(deptoCode);
    getAllEvents();
    // getMyLastDocuments();
    // getEvents("seguridad");
  }, []);

  useEffect(() => {
    if (municipalities) {
      const aux = transformToSelectArray(
        municipalities,
        "municipio",
        "c_digo_dane_del_municipio"
      );
      const sortedData = [...aux].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setMunicipalitiesList(sortedData);
      setDataMunicipalities();
    }

    if (Array.isArray(events)) {
      const violence = events.filter(
        (event) => event.eventCategoryId === "violencia"
      );
      const health = events.filter(
        (event) => event.eventCategoryId === "salud"
      );
      const auxViolence = transformToSelectArray(violence, "name", "id");
      const sortedEvents = [...auxViolence].sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      const auxHealth = transformToCheckArray(health, "name", "id", "id");
      const sortedHealth = [...auxHealth].sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      setListEvents(sortedEvents);
      setListHealthEvents(sortedHealth);

      // console.log("salud lista", auxHealth);

      setEvents();
    }
  }, [municipalities, events]);

  useEffect(() => {
    if (requestDataMap.data) {
      const dataTransformed = transformToMap(requestDataMap.data);
      if (dataTransformed.features.length === 0) {
        setMessage({
          type: "warning",
          title: "Sin resultados",
          description:
            "No se encontraron eventos con los filtros seleccionados.",
        });
      }
      setMapData(dataTransformed);
    }
    /* if (getAllDocuments.data) {
      console.log(
        "🚀 ~ useEffect ~ getAllDocuments.data:",
        getAllDocuments.data
      );
    } */
  }, [requestDataMap.data /* , getAllDocuments.data */]);

  const validateForm = (values) => {
    // Validar que al menos uno de los tipos de eventos esté seleccionado
    if (
      !(values.violence && values.violence.length > 0) &&
      !(values.health && values.health.length > 0)
    ) {
      setMessage({
        type: "error",
        title: "Error",
        description: "Por favor, selecciona al menos un tipo de evento.",
      });

      return false;
    }
    // form.validateFields(["dateRange", "zones"]);;
    return true;
  };

  const onclick = async (values) => {
    if (validateForm(values)) {
      try {
        const cityList = values.zones.map((item) =>
          item.value.replace(".", "")
        );
        const dataFilter = {
          date: {
            startDate: values.dateRange[0].format("YYYY-MM-DD"),
            endDate: values.dateRange[1].format("YYYY-MM-DD"),
          },
          events: [
            ...(values.violence && values.violence.length > 0
              ? values.violence.map((item) => item.value)
              : []),
            ...(values.health && values.health.length > 0 ? values.health : []),
          ],
          municipalities: cityList,
        };

        await requestDataMap.request(dataFilter);
      } catch (error) {
        /* empty */
      }
    }
  };

  const onHandleClickChart = () => navigate("/statement_indicators");

  return (
    <div className="flex flex-col">
      {/* <div className="flex flex-col lg:flex-row lg:space-x-4 pt-5 mx-5">
        <div className="flex flex-col lg:ml-5 mt-5 w-full lg:w-7/12">
          <div>
            <strong className="text-lightBlue2">
              ¡Hola, {currentUser.firstName} {currentUser.lastName}!
            </strong>
            <p>Accede rápidamente a tus últimos datos cargados</p>
          </div>
          <HomeLastFiles className="h-full" />
        </div>
        <HomePreviewIndicatorChart
          onHandleClickChart={onHandleClickChart}
          className="w-2/3 lg:w-5/12 mt-5 lg:mt-0"
        />
      </div> */}
      <Row className="justify-between">
        <Col xs={24} md={12} lg={12} xl={16}>
          <MapCard data={mapData} />
        </Col>
        <Col xs={24} md={8} lg={12} xl={8}>
          <Form form={form} name="home-form">
            <HomeForm
              onSelectItemZone={handleItemZone}
              onSelectItemEvent={handleItemEvent}
              zones={zonesSelected}
              events={eventsSelected}
              municipalities={municipalitiesList}
              violenceList={listEvents}
              healthList={listHealthEvents}
              form={form}
              onClick={() => onclick(form.getFieldsValue())}
            />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default HomeTemplate;
