// App.js
import { Form } from "antd";
import React, { useState,useEffect } from "react";
import dayjs from "dayjs";
import SubmitButtonNotValidate from "../../atoms/Buttons/SubmitButtonNotValidate";
import InvestigationDescriptionForm from "../../organisms/InvestigationForm/InvestigationDescriptionForm";

const InvestigationFormTemplate = ({
  action,
  listMunicipalities,
  hasTitle = false,
  dataDescription,
  handlerSubmit,
  loading,
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  
  // const [uploadValidated, setUploadValidated] = useState(false);


  // useEffect(() => {
  //   if (fileList.length > 0) {
  //     setUploadValidated(true);
  //   } else {
  //     setUploadValidated(false);
  //   }
  // }, [fileList]);

  // const dayFormat = "DD/MM/YYYY";
  useEffect(() => {
    if (dataDescription && action === "edit") {
      let auxDate = dataDescription?.date;
      const image = dataDescription?.imageUrl;
      setDefaultImageUrl(image)
      const [date] = auxDate.split("T")
      auxDate = dayjs(date);
      const auxData = { ...dataDescription,
        date: auxDate  
      };
      form.setFieldsValue(auxData);
    }
  }, [dataDescription]);

  const dateInputValidator = () => [
    {
      validator: async (_, value) => {
        const valueDate = form.getFieldValue("dateColumn");
        if (!value && valueDate === "0") {
          return Promise.reject(
            new Error(
              "Este campo es requerido si 'Columna de Fecha de muestra' está vacía o en Ninguno"
            )
          );
        }
        return Promise.resolve();
      },
    },
    {
      required: true,
      message: "",
    },
  ];

  const municipalityInputValidator = () => [
    {
      validator: async (_, value) => {
        const valueMun = form.getFieldValue("municipalityColumn");
        if (!value && valueMun === "0") {
          return Promise.reject(
            new Error(
              "Este campo es requerido si 'Columna de Municipio' está vacío o en Ninguno"
            )
          );
        }
        return Promise.resolve();
      },
    },
    {
      required: true,
      message: "",
    },
  ];

  const handlerSubmitButton = (data) => {
    const auxData = { ...data, upload: fileList[0] };
    handlerSubmit(auxData);
  };

  
  return (
    <Form
      form={form}
      onFinish={handlerSubmitButton}
      className="font-Poppins "
      scrollToFirstError
    >
      {hasTitle ? (
        <p className="my-5 text-lg text-lightBlue2 font-bold">
          Descripción del Documento
        </p>
      ) : null}
      <div className="flex flex-col items-center ">
        <div className=" p-2">
          <div className="flex justify-center items-center ">
            <InvestigationDescriptionForm
              listMunicipalities={listMunicipalities}
              validatorMunicipality={municipalityInputValidator}
              validatorDate={dateInputValidator}
              dataDescription={dataDescription}
              defaultImageUrl={defaultImageUrl}
              settingFileList={setFileList}
            />
          </div>
          <Form.Item>
            <div className="flex justify-center text-center">
              <SubmitButtonNotValidate
                nameButton={action === "create" ? "Crear" : "Guardar"}
                form={form}
                loading={loading}
                htmlType="submit"
              />
            </div>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
export default InvestigationFormTemplate;
