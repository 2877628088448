import { Card } from "antd";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import PieChart from "../../atoms/Graphs/PieChart";
import BarChart from "../../atoms/Graphs/BarChart";
import StackedBarChart from "../../atoms/Graphs/StackedBarChart";
import TimeSeriesChartLine from "../../atoms/Graphs/TimeSeriesChartLine";
import ScatterPlot from "../../atoms/Graphs/ScatterPlot";
import MapCard from "../../organisms/MapCard/MapCard";

const CardChart = ({ typeChart, title, titleX, titleY, data, handleEdit, handleDelete }) => {
  const datammap = {
    "column": {
      "message": "data map",
      "data": [
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        },
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.76384,
              1.807122444
            ]
          },
          "properties": {
            "name": "TUMACO"
          }
        }
      ]
    }
  }

  const renderChart = () => {
    if (typeChart === "stackedBar")
      return (
        <StackedBarChart
          title={title}
          dataChart={data || []}
          titleX={titleX || ""}
          titleY={titleY || ""}
        />
      );
    if (typeChart === "bar")
      return (
        <BarChart
          title={title}
          axisX={data ? data.x : []}
          axisY={data ? data.y : []}
          titleX={titleX || ""}
          titleY={titleY || ""}
        />
      );
    if (typeChart === "line")
      return (
        <TimeSeriesChartLine
          title={title}
          axisX={data ? data.x : []}
          axisY={data ? data.y : []}
          titleX={titleX || ""}
          titleY={titleY || ""}
        />
      );
    if (typeChart === "pie")
      return (
        <PieChart
          title={title}
          values={data ? data.y : []}
          labels={data ? data.x : []}
          titleX={titleX || ""}
          titleY={titleY || ""}
        />
      );
    if (typeChart === "scatter")
      return (
        <ScatterPlot
          title={title}
          dataPlot={data || []}
          titleX={titleX || ""}
          titleY={titleY || ""}
        />
      );
    if (typeChart === "map")
      return (
        <MapCard
          data={datammap}
          initialLng={-77.269577}
          initialLat={1.258999}
          initialZoom={7}
          mapStyle="mapbox://styles/mapbox/light-v10"
          accessToken="pk.eyJ1Ijoic2FtaTkxNSIsImEiOiJjbG94dnNzMGQxZjRoMmluemo5N3JicWZxIn0.qf1xB4qUh2iam2aWrWbmRQ"
          height={300}
        />
      );

    return null;
  };
  return (
    <Card
      className="bg-white !rounded-large w-[90%] lg:w-[45%] h-[520px] ml-2 mb-2"
      key={title}
    >
      <div className="flex space-x-3 w-full justify-end">
        <button type="button" aria-label="button" onClick={handleEdit}>
          <EditOutlined className="text-2xl hover:text-[#615f5e]" />
        </button>
        <button type="button" aria-label="button" onClick={handleDelete}>
          <CloseCircleOutlined className="text-2xl hover:text-[#E23B30]" />
        </button>
      </div>
      {renderChart()}
    </Card>
  );
};

export default CardChart;
