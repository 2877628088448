/* eslint-disable react/jsx-boolean-value */
import "./App.css";

import "./index.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { ConfigProvider } from "antd";
// import Home from "./views/Home";
// styles for table
import { inputStyle, tableStyle } from "./assets/theme/themeConfig";

// componets
import ROUTES from "./constant/router";
import LoginPage from "./views/Login";
import Register from "./views/User/UserCreate";
import UpdateUserPage from "./views/User/UserUpdate";
import Home from "./views/Home";
import Report from "./views/Report";
import Statement from "./views/Statement";
import ViewInvestigation from "./views/Investigation/viewInvestigation";
import DocumentManagement from "./views/DocumentManagement";
// import DocumentRegister from "./views/DocumentManagement/Register";
// import Indicator from "./views/Indicator";
// import DocumentRegister from "./views/Document/Register";
// import DocumentDescription from "./views/Document/Description";
import ColumnDocument from "./views/ColumnDocument";
import Footer from "./components/organisms/Footer/Footer";
import UsersList from "./components/templates/UserTemplate/UsersList";
import RecoveryPage from "./views/Login/RecoveryPage";
import StatementIndicators from "./views/StatementIndicators";
import IndicatorsAdmin from "./views/Indicator/IndicatorsAdmin";
import PermissionRoute from "./routers/PermissionRoute";
import AdminInvestigation from "./views/Investigation/AdminInvestigation";
import Investigation from "./views/Investigation/Investigation";
import ComparativeAnalysis from "./views/ComparativeAnalysis";
import LandingPage from "./views/LandigPage";
// import useTokenMonitor from "./hooks/useTokenMonitor";
import amplifyConfig from "./amplifyConfig";
import useAuthMonitor from "./hooks/useAuthMonitor";
import Header from "./components/organisms/Header/Header";
import NotRequireAuth from "./routers/NotRequireAuth";
import Indicator from "./views/Indicator";

// import RegisterUserPage from "./views/Register";

const App = () => {
  Amplify.configure(amplifyConfig);
  useAuthMonitor();
  // useTokenMonitor();

  const componentWithHeader = (component) => (
    <>
      <Header />
      {component}
    </>
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
          colorPrimary: "#4cacbc",
          colorBgBase: "#f0f0f0",
        },
        font: {
          family: "Poppins, sans-serif",
        },

        components: {
          Table: tableStyle,
          Input: inputStyle,
        },
      }}
    >
      <Routes>
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
        <Route
          exact
          path={ROUTES.GENERAL.HOME}
          element={componentWithHeader(
            <PermissionRoute>
              <Home />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.REPORT}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="report">
              <Report />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.STATEMENT}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="statement">
              <Statement />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.STATEMENT_INDICATORS}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="indicator.readreport">
              <StatementIndicators />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.INVESTIGATION_ADMIN}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="research.read">
              <AdminInvestigation />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.INVESTIGATION}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="researchpublished.read">
              <Investigation />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.INVESTIGATION_INFO}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="research.read">
              <ViewInvestigation />
            </PermissionRoute>
          )}
        />

        <Route
          exact
          path={ROUTES.GENERAL.DOCUMENTS}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="document_management">
              <DocumentManagement />
            </PermissionRoute>
          )}
        />
        {/* <Route
          exact
          path={ROUTES.GENERAL.DOCUMENT_UPLOAD}
          element={
              <DocumentRegister />
          }
        /> */}
        <Route
          exact
          path={ROUTES.GENERAL.DOCUMENT_INFO}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="document_management">
              <ColumnDocument />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.INDICATORS}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="indicator.read">
              <IndicatorsAdmin />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.INDICATORS_ADMIN}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="indicator.create">
              <Indicator />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.COMPARATIVE_ANALYSIS}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="comparative_analysis">
              <ComparativeAnalysis />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.COMPARATIVE_ANALYSIS_DETAIL}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="comparative_analysis">
              <ComparativeAnalysis />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.USER_EDIT}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="userown">
              <UpdateUserPage />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.GENERAL.USERS_LIST}
          element={componentWithHeader(
            <PermissionRoute permissionToAccess="user.read">
              <UsersList />
            </PermissionRoute>
          )}
        />
        <Route
          exact
          path={ROUTES.AUTH.USER_CREATE}
          element={
            <NotRequireAuth>
              <Register />
            </NotRequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.AUTH.LOGIN}
          element={
            <NotRequireAuth>
              <LoginPage />
            </NotRequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.HOME.LANDINGPAGE}
          element={
            <NotRequireAuth>
              <LandingPage />
            </NotRequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.AUTH.RECOVERY}
          element={
            <NotRequireAuth>
              <RecoveryPage />
            </NotRequireAuth>
          }
        />
        {/* <Route
          path={ROUTES.AUTH.USER_CREATE}
          element={
              <PermissionRoute permissionToAccess="user.create">
                <Register />
              </PermissionRoute>
          }
        /> */}
      </Routes>
      <Footer />
    </ConfigProvider>
  );
};

export default App;
