import React, { useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";
import Colors from "../../../utils/ColorsPage.json";

const TimeSeriesChartLine = ({
  axisX,
  axisY,
  name = "",
  title,
  iteration = 0,
  height = "100%",
  bgColorChart = "#FFFFFF",
  titleX,
  titleY,
}) => {
  const chartRef = useRef(null);
  
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        window.requestAnimationFrame(() => {
          Plotly.Plots.resize(chartRef.current);
        });
      }
    };
    // Set initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const returnLayout = () => {
    let layout = {
      title,
      plot_bgcolor: bgColorChart,
      paper_bgcolor: bgColorChart,
      xaxis: { title: titleX},
      yaxis: { title: titleY },
    };
    if (Array.isArray(axisX)) {
      const auxValue = Number(axisX[0])
      if (Number.isNaN(auxValue)) {
        layout = {
          ...layout,
          xaxis: {
            title: titleX,
            rangeselector: {
              buttons: [
                {
                  count: 1,
                  label: "1 mes",
                  step: "month",
                  stepmode: "backward",
                },
                {
                  count: 6,
                  label: "6 meses",
                  step: "month",
                  stepmode: "backward",
                },
                {
                  count: 1,
                  label: "1 año",
                  step: "year",
                  stepmode: "todate",
                },
                {
                  label: "Todos",
                  step: "all",
                },
              ],
            },
            rangeslider: {
              visible: true,
            },
            type: "date",
          },
        };
      }
    }
    return layout;
  };

  return (
    <div ref={chartRef} style={{ width: "100%", height }}>
      <Plot
        data={[
          {
            x: axisX,
            y: axisY,
            type: "line",
            mode: "lines+markers",
            name,
            line: {
              color: Colors[iteration],
            },
          },
        ]}
        layout={returnLayout()}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default TimeSeriesChartLine;
