import api from ".";
import newApi from "./newApi";
import { instance as client } from "./client_document";

const dataSource = [
  {
    key: "1",
    nombre: "Indicador Económico",
    tipo: "Financiero",
    formula: "PIB = C + I + G + (X - M)",
  },
  {
    key: "2",
    nombre: "Indicador Demográfico",
    tipo: "Poblacional",
    formula:
      "Tasa de Crecimiento = (Población Final - Población Inicial) / Población Inicial",
  },
  {
    key: "3",
    nombre: "Indicador Ambiental",
    tipo: "Ecológico",
    formula: "Huella de Carbono = Suma de emisiones de CO2",
  },
  {
    key: "4",
    nombre: "Indicador de Salud",
    tipo: "Sanitario",
    formula: "Tasa de Mortalidad = Muertes / Población Total",
  },
  {
    key: "5",
    nombre: "Indicador Tecnológico",
    tipo: "Innovación",
    formula: "Índice de Innovación = Inversiones en I+D / Ingresos Totales",
  },
  {
    key: "6",
    nombre: "Indicador Educativo",
    tipo: "Académico",
    formula:
      "Tasa de Alfabetización = Población Alfabetizada / Población Total",
  },
];
// #region Advertisements
const getIndicators = (pageNumber = 1, page_size = 10, search = "") => {
  // const url = `/web/v1/Indicator/advertising?page[size]=${page_size}&page[number]=${pageNumber}${search}`;
  // eslint-disable-next-line no-console
  console.log("get indicators", pageNumber, page_size, search);
  return dataSource;
};

const getDataIndicator = ({
  pageNumber = 1,
  page_size = 10,
  search = "total_population_men",
  startDate = "2000-01-01",
  endDate = "2035-12-31",
  // variable = "Demográficos",
  municipalities = "",
}) => {
  let municipalitiesParams = `&filter[city]=${municipalities}`;
  if (Array.isArray(municipalities) && municipalities.length > 0) {
    municipalitiesParams = municipalities.map(
      (city) => `&cities=${city}`
    );
    municipalitiesParams = municipalitiesParams.join("");
  }
  const url = `/indicators/tracer_indicators?limit=${page_size}&offset=${pageNumber}&name=${search}&start_date=${startDate}&end_date=${endDate}${municipalitiesParams}`;
  return newApi.get(url);
};
const getDataIndicatorRegion = ({
  pageNumber = 1,
  page_size = 10,
  search = "total_population_men",
  startDate = "2000-01-01",
  endDate = "2035-12-31",
  variable = "Demográficos",
  subRegion = "",
}) => {
  let subRegionParams = `&filter[subRegion]=${subRegion}`;
  if (Array.isArray(subRegion) && subRegion.length > 0) {
    subRegionParams = subRegion.map((city) => `&filter[city]=${city}`);
    subRegionParams = subRegionParams.join("");
  }
  const url = `/files/calculated-indicators?limit=${page_size}&offset=${pageNumber}&filter[name]=${search}&filter[type]=${variable}&filter[startDate]=${startDate}&filter[endDate]=${endDate}${subRegionParams}`;
  return api.get(url);
};

const getGroupIndicators = () => {
  const url = "/files/indicator-groups";
  return api.get(url);
};

const getIndicatorsByGroup = (idGroup) => {
  const url = `/files/indicator-types/${idGroup}`;
  return api.get(url);
};

const getMunicipalitiesByIndicator = (idIndicador) => {
  const url = `/indicators/tracer_indicators/indicator_municipalities?type=${idIndicador}`;
  return newApi.get(url);
};
const getRegionByIndicator = (idIndicador) => {
  const url = `/files/indicator-municipalities/${idIndicador}/${true}`;
  return api.get(url);
};
const getIndicatorsTaxonomies = () => {
  const url = "/files/all-indicator-groups/";
  return client.get(url);
};

const createPostulateIndicator = (data) => {
  const url = "/indicators/proposal";
  return newApi.post(url, data);
};

const deletePostulateIndicator = (data) => {
  const url = "/files/extraction-indicators/delete";
  return api.post(url, data);
};

const getPostulatesIndicator = (id, page_size, page) => {
  const url = `/indicators/proposal?indicatorGroup=${id}&limit=${page_size}&offset=${page}`;
  return newApi.get(url);
};

const updateProposal = (id, data) => {
  const url = `/indicators/proposal/${id}`;
  return newApi.put(url, data);
};

const getIndicatorGroups = () => {
  const url = "/indicators/indicator/list_deep";
  return newApi.get(url);
};

const getIndicatorById = (id) => {
  const url = `/indicators/indicator/get_indicators/${id}`;
  return newApi.get(url);
};

const getListTaxonomies = () => {
  const url = "/indicators/get_taxonomies";
  return newApi.get(url);
};

const updateTaxonomies = () => {
  const url = "/indicators/update_taxonomies";
  return newApi.get(url);
};


export default {
  getDataIndicator,
  getIndicators,
  getGroupIndicators,
  getIndicatorsByGroup,
  getMunicipalitiesByIndicator,
  getIndicatorsTaxonomies,
  createPostulateIndicator,
  deletePostulateIndicator,
  getRegionByIndicator,
  getDataIndicatorRegion,
  getPostulatesIndicator,
  getIndicatorGroups,
  getIndicatorById,
  getListTaxonomies,
  updateProposal,
  updateTaxonomies
};
