// Objetivo: login de usuario
// retornar siempre false recibe email y password

import api from ".";
import newApi from "./newApi";

import client_no_auth from "./client_no_auth";
// const login =  async ( email, password) => axios.post("http://localhost:8080/api/auth/login", {email, password});


const login = () => {
  const url = "/users/login";
  return newApi.get(url);
};


const recoveryPswNoAuth = (data) => {
  const url = "/auth/forgot-password";
  return client_no_auth.post(url, data);
};
const changePswNoAuth = (data) => {
  const url = "/auth/recovery";
  return client_no_auth.post(url, data);
};
const recoveryPswAuth = () => {
  const url = "/auth/change-password";
  return api.get(url);
};

const changePswAuth = (data) => {
  const url = "/auth/change-password";
  return api.post(url, data);
};

const getPermissions = () => {
  const url = "/auth/permissions";
  return api.get(url);
};

export default {
  login,
  recoveryPswNoAuth,
  recoveryPswAuth,
  changePswNoAuth,
  changePswAuth,
  getPermissions,
};
