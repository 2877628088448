import api from ".";
import newApi from "./newApi";

const getEvents = (EventType) => {
  const url = `/events/events?events=${EventType}`;
  return newApi.get(url);
};

/* const getEvents=(EventType)=>{
  const url=`/files/event-types?type=${EventType}`;
  return api.get(url);
} */

const getAllEvents = () => {
  const url = "/files/event-types";
  return api.get(url);
};

const getMunicipalities = (subregions = false, group_by_subregion = true) => {
  const url = `/events/municipalities?subregions=${subregions}&group_by_subregion=${group_by_subregion}`;
  return newApi.get(url);
};

const getDataMap = (data) => {
  const url = "/files/events";
  const result = api.post(url, data);
  return result;
};

export default {
  getEvents,
  getAllEvents,
  getDataMap,
  getMunicipalities,
};
