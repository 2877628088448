const transformToCheckArray = (array, labelID, valueID, id) => {
  if (!Array.isArray(array)) return "";
  if (Array.isArray(labelID)) {
    const newArray = array.map((item) => {
      const newItem = item[valueID];
      const objKey = labelID.find(val=> item[val] )
      const idKey = id.find(val=> item[val] )
      return {
        label: item[objKey] || "",
        value: newItem || "",
        id: item[idKey] || "",
      };
    });
    return newArray;
  }
  if (!labelID && !valueID && !id)
    return array.map((item) => ({ id: item, label: item, value: item }));
  const newArray = array.map((item) => {
    const newItem = item[valueID];

    return {
      id: item[id] || "",
      label: item[labelID] || "",
      value: newItem || "",
    };
  });
  return newArray;
};

export default transformToCheckArray;
