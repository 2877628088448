import React from "react";
import { Row, Avatar, Dropdown, Space, Col } from "antd";
// import { UserOutlined } from "@ant-design/icons";
// import "../../../index.css";
import AppText from "../../atoms/Text/AppText";
import ROUTES from "../../../constant/router";
import getInitials from "../../../helpers/getInitials";
import ButtonLink from "../../atoms/Link/ButtonLink";


const DropdownAvatar = ({handleMenuClick, infoUser, className}) => {

  const items = [
    {
      label: <ButtonLink href={ROUTES.GENERAL.USER_EDIT} title="Ver mi información" />,
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: "Cerrar Sesión",
      key: "logout",
    },
  ];

  // const handleMenuClick = (e) => {
  //   console.log("click", e);
  //   if (e.key === "logout") {
  //     logoutUser()
  //   }
  // };

  const initials = getInitials(infoUser.firstName, infoUser.lastName)

  return (
    <div className="grid justify-items-end min-w-[262px]">
      <Dropdown
        menu={{
          items,
          onClick: handleMenuClick,
        }}
        trigger={["click"]}
      >
        {/* <button type="button" onClick={(e) => e.preventDefault()} className="shadow-xl h-auto  rounded-xl w-48 inline-block text-sm px-4 py-2 leading-none  text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0 "> */}
        <Row className={className}>
          <Space className="gap-3" >
            <Col>
              <Space direction="vertical" className="gap-0 text-right" >
                <AppText title={`${infoUser.firstName} ${infoUser.lastName}`} type="secondary" className="text-lightBlue font-bold uppercase text-lg"  />
                <AppText
                  title={infoUser.role}
                  type="secondary"
                  className="italic text-gray_custom  text-base"
                  style={{
                    marginTop: 0,
                  }} />
              </Space>
            </Col>
            <Col>
              <Avatar style={{ backgroundColor: "#fde3cf", color: "#f56a00" }} size={60}>{initials}</Avatar>
              {/* <Avatar
                style={{
                  backgroundColor: "#FFFFFF",
                  verticalAlign: "middle",
                }}
                size={60}
                icon={<UserOutlined  style={{ color: "#08c" }}/>}
              /> */}
            </Col>
          </Space>
        </Row>
        {/* </button> */}
      </Dropdown>
    </div >
  );
}

export default DropdownAvatar;


