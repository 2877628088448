import React, { useEffect, useState } from "react";
import Tour from "reactour";
import { FloatButton, Spin } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import indicator from "../../api/indicator";
import TimeSeriesChartLine from "../../components/atoms/Graphs/TimeSeriesChartLine";
import useApi from "../../api/useApi";
import { useAuth } from "../../contexts/AuthContext";
import FiltersStatementIndicatorsForm from "../../components/organisms/FiltersForm/FiltersStatementIndicatorsForm";
import Card from "../../components/atoms/Card/Card";
import AppTitle from "../../components/atoms/Title/AppTitle";
import transformToTreeSelectRegions from "../../helpers/transformToTreeSelectRegions";
import { addPropsToTree } from "../../helpers/depthArray";

const steps = [
  {
    selector: ".float-statement",
    content: "Click here to add a new chart.",
  },
  {
    selector: ".chart",
    content: "This is where your chart will appear.",
  },
  // Add more steps as needed
];

const municip = [
  {
    subRegion: "CENTRO",
    municipalities: [
      {
        name: "PASTO",
        subRegion: "CENTRO",
        code: "PASTO*52001",
      },
    ],
  },
  {
    subRegion: "CENTRO OCCIDENTE",
    municipalities: [
      {
        name: "LA LLANADA",
        subRegion: "CENTRO OCCIDENTE",
        code: "LA LLANADA*52385",
      },
      {
        name: "MALLAMA",
        subRegion: "CENTRO OCCIDENTE",
        code: "MALLAMA*52435",
      },
      {
        name: "RICAURTE",
        subRegion: "CENTRO OCCIDENTE",
        code: "RICAURTE*52612",
      },
    ],
  },
  {
    subRegion: "COSTA",
    municipalities: [
      {
        name: "BARBACOAS",
        subRegion: "COSTA",
        code: "BARBACOAS*52079",
      },
      {
        name: "EL CHARCO",
        subRegion: "COSTA",
        code: "EL CHARCO*52250",
      },
      {
        name: "FRANCISCO PIZARRO",
        subRegion: "COSTA",
        code: "FRANCISCO PIZARRO*52520",
      },
      {
        name: "LA TOLA",
        subRegion: "COSTA",
        code: "LA TOLA*52390",
      },
      {
        name: "MAGÜÍ",
        subRegion: "COSTA",
        code: "MAGÜÍ*52427",
      },
      {
        name: "MOSQUERA",
        subRegion: "COSTA",
        code: "MOSQUERA*52473",
      },
      {
        name: "OLAYA HERRERA",
        subRegion: "COSTA",
        code: "OLAYA HERRERA*52490",
      },
      {
        name: "ROBERTO PAYÁN",
        subRegion: "COSTA",
        code: "ROBERTO PAYÁN*52621",
      },
      {
        name: "SAN ANDRÉS DE TUMACO",
        subRegion: "COSTA",
        code: "SAN ANDRÉS DE TUMACO*52835",
      },
      {
        name: "SANTA BÁRBARA",
        subRegion: "COSTA",
        code: "SANTA BÁRBARA*52696",
      },
    ],
  },
  {
    subRegion: "NORTE",
    municipalities: [
      {
        name: "EL TABLÓN DE GÓMEZ",
        subRegion: "NORTE",
        code: "EL TABLÓN DE GÓMEZ*52258",
      },
    ],
  },
];

const StatementIndicators = () => {
  const getIndicatorGroups = useApi(indicator.getIndicatorGroups);
  const getDataIndicator = useApi(indicator.getDataIndicator);
  const getDataIndicatorRegion = useApi(indicator.getDataIndicatorRegion);
  const getMunicipalitiesByIndicator = useApi(
    indicator.getMunicipalitiesByIndicator
  );
  const getRegionByIndicator = useApi(indicator.getRegionByIndicator);
  const [listCharts, setListCharts] = useState([]);
  const [positionChart, setPositionChart] = useState(0);
  const [dataSelectors, setDataSelectors] = useState({
    groupIndicators: "",
    municipalitiesByIndicator: "",
  });
  const [loadingChart, setLoadingChart] = useState(true);
  const [loadingForm, setLoadingForm] = useState(true);
  const [loading, setLoading] = useState({
    indicatorsByGroup: false,
    municipalitiesByIndicator: false,
    chart: false,
  });
  const [municipality, setMunicipality] = useState(
    "Población total mujeres - Tumaco"
  );
  const { setMessage } = useAuth();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [listSubRegions, setListSubRegions] = useState([]);

  const fetchDataGraph = (request, type_location) => {
    setLoading((prev) => ({ ...prev, chart: true }));
    if (type_location === "subRegion") {
      getDataIndicatorRegion.request(request);
    } else {
      getDataIndicator.request(request);
    }
  };

  useEffect(() => {
    setLoadingChart(true);
    setLoadingForm(true);
    getIndicatorGroups.request();
    fetchDataGraph({
      pageNumber: 1,
      page_size: 100,
      search: "total_population_women",
      municipalities: [52835, 52836],
    });
  }, []);

  useEffect(() => {
    if (getDataIndicator.data) {
      const auxListChart = [...listCharts];
      auxListChart[positionChart] = {
        dataSource: getDataIndicator.data?.data?.items,
        municipality,
      };
      setListCharts(auxListChart);
      setLoadingChart(false);
      setLoading((prev) => ({ ...prev, chart: false }));
      getDataIndicator.setData();
    }
    if (getDataIndicatorRegion.data) {
      const auxListChart = [...listCharts];
      auxListChart[positionChart] = {
        dataSource: getDataIndicatorRegion.data?.data,
        municipality,
      };
      setListCharts(auxListChart);
      setLoadingChart(false);
      setLoading((prev) => ({ ...prev, chart: false }));
      getDataIndicatorRegion.setData();
    }
    if (getIndicatorGroups.data) {
      const auxGroup = addPropsToTree(getIndicatorGroups.data.data);
      setDataSelectors((prev) => ({
        ...prev,
        groupIndicators: auxGroup,
      }));
    }
    if (getMunicipalitiesByIndicator.data) {
      const auxMunicipalities = transformToTreeSelectRegions(
        municip,
        "subRegion",
        "subRegion",
        "Narino",
        "name",
        "code"
      );
      setDataSelectors((prev) => ({
        ...prev,
        municipalitiesByIndicator: auxMunicipalities[0].children,
      }));
      setLoading((prev) => ({ ...prev, municipalitiesByIndicator: false }));
    }
    if (getRegionByIndicator.data) {
      setListSubRegions(getRegionByIndicator.data.data);
    }
  }, [
    getDataIndicator.data,
    getDataIndicatorRegion.data,
    getIndicatorGroups.data,
    getMunicipalitiesByIndicator.data,
    getRegionByIndicator.data,
  ]);

  useEffect(() => {
    if (getDataIndicator.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos para la gráfica.",
        code: getDataIndicator.error,
      });
      setLoadingChart(false);
      setLoading((prev) => ({ ...prev, chart: false }));
    }
    if (getDataIndicatorRegion.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos para la gráfica.",
      });
      setLoadingChart(false);
      setLoading((prev) => ({ ...prev, chart: false }));
    }

    if (getIndicatorGroups.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los grupos de indicadores. Por favor recargue la pagina",
        code: getIndicatorGroups.error,
      });
    }
    if (getMunicipalitiesByIndicator.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos para la gráfica. Por favor recargue la pagina",
        code: getMunicipalitiesByIndicator.error,
      });
      setLoading((prev) => ({ ...prev, municipalitiesByIndicator: false }));
    }

    setLoadingForm(false);
  }, [
    getDataIndicator.error,
    getDataIndicatorRegion.error,
    getIndicatorGroups.error,
    getMunicipalitiesByIndicator.error,
  ]);

  const returnLabelOrValue = (toSplit, labelOrValue) => {
    if (toSplit) {
      if (labelOrValue === "label") return toSplit.split("*")[0];
      return toSplit.split("*")[1];
    }
    return "";
  };

  const handleValues = (values, i) => {
    const { municipalities, indicators } = values;
    setPositionChart(i);
    let req = {
      pageNumber: 1,
      page_size: 100,
      // search: returnLabelOrValue(indicators, "value"),
      search: indicators,
      // variable: group_indicator,
    };
    let location_type = "";
    const auxMun = municipalities[0];
    if (listSubRegions.includes(auxMun)) {
      setMunicipality(`${returnLabelOrValue(indicators, "label")} - ${auxMun}`);
      req = {
        ...req,
        subRegion: auxMun,
      };
      location_type = "subRegion";
    } else {
      setMunicipality(
        `${returnLabelOrValue(indicators, "label")} - ${returnLabelOrValue(
          auxMun,
          "label"
        )}`
      );
      req = {
        ...req,
        municipalities: returnLabelOrValue(auxMun, "value"),
      };
      location_type = "municipalities";
    }
    fetchDataGraph(req, location_type);
  };

  const handleRequestMunicipalities = (/* value */) => {
    setLoading((prev) => ({ ...prev, municipalitiesByIndicator: true }));
    // getMunicipalitiesByIndicator.request(returnLabelOrValue(value, "value"));
    // getMunicipalitiesByIndicator.request(value);
    getMunicipalitiesByIndicator.setData(municip)
  };

  /*   const handleRequestRegion = (value) => {
    getRegionByIndicator.request(returnLabelOrValue(value, "value"));
  }; */

  const handleClickAddChart = () => {
    if (listCharts.length < 5) {
      setListCharts((prev) => [...prev, { dataSource: [], municipality: "" }]);
    } else {
      setMessage({
        type: "error",
        title: "Error",
        description: "No se puede agregar mas de 5 gráficas por el momento.",
      });
    }
  };

  const handleRemoveChart = (i) => {
    if (listCharts.length > 1) {
      const auxListChart = [...listCharts];
      auxListChart.splice(i, 1);
      setListCharts(auxListChart);
    } else {
      setMessage({
        type: "error",
        title: "Error",
        description: "Debe haber al menos una gráfica.",
      });
    }
  };

  const renderCharts = () => {
    if (!loadingChart && !loadingForm) {
      return listCharts.map((chart, i) => {
        const x = chart?.dataSource?.map((val) => val?.date?.split("T")[0]);
        const y = chart?.dataSource?.map((val) => val?.value);
        const name = chart?.dataSource[0]?.name;
        const chartKey = chart?.dataSource?.[0]?.id || `chart-${i}`;

        return (
          <Card
            key={chartKey}
            className="flex flex-col-reverse relative md:flex-row w-full mb-5 !rounded-large chart"
          >
            <div className="w-full md:w-3/4">
              <TimeSeriesChartLine
                axisX={x}
                axisY={y}
                name={name}
                title={chart?.municipality}
                iteration={i}
                height="500px"
                bgColorChart="#F0F0F0"
              />
            </div>
            <div className="flex flex-col w-full md:w-1/4 justify-center">
              <FiltersStatementIndicatorsForm
                handleValues={(val) => handleValues(val, i)}
                dataSelectors={dataSelectors}
                handleRequestMunicipalities={handleRequestMunicipalities}
                // handleRequestRegion={handleRequestRegion}
                loading={loading}
              />
            </div>
            <button
              onClick={() => handleRemoveChart(i)}
              className="absolute h-6 w-6 top-2 right-2 rounded-full text-[#D94A38] hover:bg-light_gray"
              type="button"
              aria-label="close"
            >
              <CloseOutlined />
            </button>
          </Card>
        );
      });
    }
    return (
      <div className="flex w-full h-full items-center justify-center">
        <Spin />
      </div>
    );
  };

  return (
    <div className="flex justify-between mt-10 min-h-96">
      <div className="flex flex-col justify-center items-center mb-4 w-full ">
        <AppTitle
          title="Gráficas de Indicadores Trazadores"
          level={2}
          className="text-lightBlue2 font-bold text-center w-full"
        />
        <div className="flex flex-col px-1 md:px-10 w-full">
          {renderCharts()}
        </div>
      </div>

      <FloatButton
        onClick={handleClickAddChart}
        icon={<PlusOutlined className="text-white_custom" />}
        className="float-statement"
        tooltip={
          <div className="italic font-light float-statement-tooltip">
            Agregar una nueva gráfica
          </div>
        }
        type="default"
        style={{
          right: 50,
          bottom: 50,
        }}
      />
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </div>
  );
};

export default StatementIndicators;
