/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { Form, Upload, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import AppButton from "../../atoms/Buttons/AppButton";


const FormUpload = ({ name, label, labelCol, rules, className, settingFile }) => {
  const [fileList, setFileList] = useState([]);

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };
  const handleChange = ({ fileList: newFileList }) =>{
    if (newFileList.length > 0 && [
      "application/pdf"
    ].includes(newFileList[0].type)) {
      setFileList(newFileList);
      settingFile([newFileList[0]]);
    }

  }
  const beforeUpload = (file) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("Solo puedes subir archivos PDF!");
      return true;
    }
    setFileList([file]);
    // return isPdf || Upload.LIST_IGNORE;
    return false;
  };

  return (
    <Form.Item
      name={name}
      label={label ? <span className="font-Poppins font-light text-sizeBase text-color_text">{label}</span> : null}
      labelCol={labelCol}
      className="font-Poppins text-black_custom "
      rules={rules}
    // style={{ "width": "131px", "height": "43px" }}
    >
      <Upload
        // action={accion} 
        className={className}
        multiple={false}
        maxCount={1}
        accept="application/pdf"
        beforeUpload={beforeUpload}
        onChange={handleChange}
        onRemove={onRemove}
        fileList={fileList}

      >
        {fileList.length >= 1 ? null : (<AppButton
          type=" primary"
          shape="round"
          size="large"
          className="bg-lightBlue2"
          // style={{ "width": "131px", "height": "43px" }}
          title="Importar" icon={<DownloadOutlined rotate={270} />} />)}

      </Upload>
    </Form.Item>
  )
};

export default FormUpload;