import React, { useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";

const BarChart = ({ axisX, axisY, title, titleX, titleY }) => {
  const chartRef = useRef(null);
  
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        window.requestAnimationFrame(() => {
          Plotly.Plots.resize(chartRef.current);
        });
      }
    };

    // Set initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={chartRef} style={{ width: "100%", height: "100%" }}>
      <Plot
        data={[
          {
            type: "bar",
            x: axisX,
            y: axisY,
            marker: {
              color: "rgba(55,128,191,0.6)",
              width: 1,
            },
          },
        ]}
        layout={{
          title,
          xaxis: {
            title: titleX,
          },
          yaxis: {
            title: titleY,
          },
          bargap: 0.05,
          autosize: true,
        }}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default BarChart;
