import api from ".";
// import client_file from "./client_file";

// #region Advertisements

const getAllAnalysis = () => {
  const url = "/analysis/";
  return api.get(url);
};

const getAnalysisKeyword = (keyWord) => {
  const url = `/analysis/keyword?keyword=${keyWord}`;
  return api.get(url);
};
const getAnalysisById = (id) => {
  const url = `/analysis/${id}`;
  return api.get(url);
};

const createAnalysis = (data) => {
  const url = "/analysis/create ";
  return api.post(url, data);
};

const updateAnalysis = (id, data) => {
  const url = `/analysis/update/${id}`;
  return api.put(url, data);
};

const getColumnData = (id, typeChart, axisX, axisY, label, agg, values) => {
  let complement = "";
  if (typeChart === "pie" || typeChart === "bar")
    complement = `&graph[column_name]=${axisX}`;
  if (typeChart === "stackedBar")
    complement = `&graph[x_axis]=${axisX}&graph[label]=${label}&graph[agg_function]=${agg}${axisY === "none" || !axisY  ? "" : `&graph[y_axis]=${axisY}`}`;
  if (typeChart === "scatter")
    complement = `&graph[x_axis]=${axisX}&graph[y_axis]=${axisY}${label === "none" || !label ? "" : `&graph[label]=${label}`}${values === "none" || !values ? "" : `&graph[values]=${values}`}`;
  if (typeChart === "line")
    complement = `&graph[x_axis]=${axisX}&graph[y_axis]=${axisY}`;
  const url = `/analysis/column-data/${id}?graph[type]=${typeChart}${complement}`;
  return api.get(url);
};

export default {
  getAllAnalysis,
  getAnalysisKeyword,
  getAnalysisById,
  createAnalysis,
  updateAnalysis,
  getColumnData,
};
