import React from "react";
import { Tabs,Spin } from "antd";

import IframeView from "../../components/organisms/Iframeview/IframeView";
// import InvestigationPreviewTemplate from "../../components/templates/InvestigationPreviewTemplate/InvestigationPreviewTemplate";

const viewInvestigation = () => {
  const items = [
    {
      key: "1",
      label: "Vista previa",
      children: (
        <div className="p-6 rounded-[6px] border border-colorBorder/25 shadow-2xl  relative top-[-1px]">
          {/* < InvestigationPreviewTemplate /> */}
          <Spin size="large" />
        </div>
      ),
      style:
        {
          marginRight: "0px",
        },
    },
    {
      key: "2",
      label: "Datos",
      children: (
        <div className="rounded-[6px] border border-colorBorder/25 shadow-2xl relative top-[-1px]">
          
          <IframeView/>
        </div>
      ),
      
    }
  ];
  
  return (
    <div className="min-h-screen flex flex-col pt-10 px-2 md:px-2">
      <div className="flex flex-col justify-center items-center ">
        <Tabs 
          type="card" 
          defaultActiveKey="1" 
          items={items} 
          className="border-none w-9/12"
              
          
        />
        
      </div>
    </div>
    // <div className="min-h-screen flex flex-col pt-10 px-2 md:px-20">
    //   <div className="flex flex-col justify-center items-center ">
    //     <h4 className="title-views my-5">Hola mundo</h4>
    //   </div>
    // </div>
  )
};

export default viewInvestigation;
