import api from ".";
import newApi from "./newApi";
import client_file from "./client_file";
import {
  instance2 as download_file,
  instance4 as requestToS3,
} from "./client_document";
import client_img from "./client_img";
import client_etl from "./client_etl";

// #region Advertisements

const getDocumentTypes = () => {
  const url = "/files/document-type/list";
  return newApi.get(url);
};
const getAllDocuments = (
  page_size,
  page,
  filter,
  keywords,
  idIndicatorGroup
) => {
  let url = `/files/file-metadata/list?limit=${page_size}&offset=${page}`;
  if (filter) url = url.concat("&", `filter=${filter}`);
  if (keywords) url = url.concat("&", `keyword=${keywords}`);
  if (idIndicatorGroup)
    url = url.concat("&", `id_indicator_group=${idIndicatorGroup}`);
  return newApi.get(url);
};

const uploadDocument = (url, file) => client_file.put(url, file);

const getUrlUploadDoc = (data) => {
  const url = "/files/file-metadata/create";
  return newApi.post(url, data);
};

const getDescriptionDoc = (id) => {
  const url = `/files/file-metadata/file?file_id=${id}`;
  return newApi.get(url);
};
const updateDescriptionDoc = (id, data, filesSetting=[]) => {
  const url = `/files/file-metadata/update/${id}`;
  const auxData = {fileUpdate: data, filesCopySettings:filesSetting};
  return newApi.put(url, auxData);
};

const getDataDocument = (id, page_size, page) => {
  const url = `/files/file-content/get-file-content?file_id=${id}&limit=${page_size}&offset=${page}`;
  return newApi.get(url);
};

const deleteDocument = (id) => {
  const url = `/files/${id}`;
  return api.delete(url);
};

const updateDescriptionColum = (idDoc, data) => {
  const url = `/files/columns/${idDoc}`;
  return newApi.put(url, data);
};

const updateManyColumns = (idDoc, data) => {
  const url = `/files/columns/many-columns/${idDoc}`;
  return newApi.put(url, data);
};

// const updateColumSensitive = (idDoc, data) => {
//   const url = `/files/file-meta/column-sensitive/${idDoc}`;
//   return api.put(url, data);
// };

const downloadDocument = (idDoc) => {
  download_file.defaults.responseType = "blob";
  const url = `/files/file-metadata/download?file_id=${idDoc}`;
  return download_file.get(url);
};

const getUrlDocument = (idDoc) => {
  const url = `/files/file-metadata/download?file_id=${idDoc}`;
  return newApi.get(url);
};

const downloadDocumentByUrl = (url) => requestToS3.get(url);

const downloadPdf = (idDoc) => {
  download_file.defaults.responseType = "blob";
  const url = `/files/download-pdf/${idDoc}`;
  return download_file.get(url);
};

const UpdateFavoriteDocument = (idDoc, data) => {
  const url = `/files/file-metadata/favorite/${idDoc}`;
  return newApi.put(url, data);
};

const getIndicatorTypesExtraction = (id, type) => {
  const url = `/files/indicator-types-extractions/${id}/${type}`;
  return api.get(url);
};

const getCalculationSourceExtraction = (id, page_size, page) => {
  const url = `/files/calculation-source-extractions/${id}?limit=${page_size}&offset=${page}`;
  return api.get(url);
};

const updateRequestCalculation = (id, data) => {
  const url = `/files/extraction-indicators/${id}`;
  return api.put(url, data);
};
const getUrlPandasDoc = (id) => {
  const url = `/files/pandasUrl/${id}`;
  return api.get(url);
};

const getObjectToCalculateIndicators = (id, typeIndicator) => {
  const url = `/files/calculate-indicators/${id}/${typeIndicator}`;
  return api.get(url);
};

const calculateIndicators = (data) => {
  const url = "/dev/sample/test/indicators/calculate";
  return client_etl.post(url, data);
};

const startingPandasProfiling = (data) => {
  const url = "/profile/create";
  return newApi.post(url, data);
};

const getAllResearchMeta = (page_size, page) => {
  const url = `/files/research-meta?limit=${page_size}&offset=${page}`;
  return api.get(url);
};

const createResearchMeta = (data) => {
  const url = "/files/research-meta";
  return api.post(url, data);
};
const uploadImageResearchMeta = (
  file,
  fileName,
  fileExtension,
  size,
  idDocument,
  docType
) => {
  const url = `/files/research-meta/upload-image/${idDocument}?fileName=${fileName}&docType=${docType}&fileExtension=.${fileExtension}&size=${size}`;
  return client_img.post(url, file);
};
const getResearchById = (id) => {
  const url = `/files/research-data/${id}`;
  return api.get(url);
};

const getResearchMetaById = (id) => {
  const url = `/files/research-meta/${id}`;
  return api.get(url);
};

const updateResearchMeta = (id, data) => {
  const url = `/files/research-meta/${id}`;
  return api.put(url, data);
};

const getUrlImageResearchMeta = (id) => {
  const url = `/files/research-meta/image-url/${id}`;
  return api.get(url);
};

const previousStateResearchMeta = (id) => {
  const url = `/files/research-meta/previous-state/${id}`;
  return api.put(url);
};

const postWebSource = (data) => {
  const url = "/files/file-meta/web";
  return api.post(url, data);
};

const validUrl = (data) => {
  const url = "/files/file-metadata/validate-url";
  return newApi.post(url, data);
};

const getInfoSharedFile = (id) => {
  const url = `/files/file-metadata/shared-users/${id}`;
  return newApi.get(url);
};

const updateInfoSharedFile = (id, data) => {
  const url = `/files/file-metadata/shared-users/${id}`;
  return newApi.post(url, data);
};

const updateComparative = (id) => {
  const url = `/files/file-meta/shared-users/${id}`;
  return api.put(url);
};

const getColumnsFile = (fileId) => {
  const url = `/files/columns/${fileId}`;
  return newApi.get(url);
};

export default {
  getAllDocuments,
  getDocumentTypes,
  uploadDocument,
  getUrlUploadDoc,
  getDescriptionDoc,
  updateDescriptionDoc,
  getDataDocument,
  updateDescriptionColum,
  downloadDocument,
  getIndicatorTypesExtraction,
  updateRequestCalculation,
  calculateIndicators,
  getUrlPandasDoc,
  getAllResearchMeta,
  getResearchById,
  createResearchMeta,
  getResearchMetaById,
  updateResearchMeta,
  uploadImageResearchMeta,
  getUrlImageResearchMeta,
  previousStateResearchMeta,
  getObjectToCalculateIndicators,
  postWebSource,
  getCalculationSourceExtraction,
  validUrl,
  getInfoSharedFile,
  updateInfoSharedFile,
  // updateWebSource,
  updateComparative,
  deleteDocument,
  downloadPdf,
  UpdateFavoriteDocument,
  startingPandasProfiling,
  getUrlDocument,
  downloadDocumentByUrl,
  getColumnsFile,
  updateManyColumns,
};
